'use client';
import { Dispatch, SetStateAction, useState } from 'react';

/**
 * Provides modal state and functions to open + close a modal
 * @returns [isActive, toggleIsActive, setIsActive]
 */
export const useModal = (): [
  boolean,
  () => void,
  Dispatch<SetStateAction<boolean>>,
] => {
  const [isActive, setIsActive] = useState(false);
  const toggleIsActive = () => setIsActive((prevIsActive) => !prevIsActive);

  return [isActive, toggleIsActive, setIsActive];
};
