'use client';

import c from 'classnames';
import { DynamicIcon } from 'phosphor-icons/react';
import { Fragment } from 'react';

type CategorySelectorProps = {
  categories: Category[];
};

type Category = {
  title: string;
  value: string;
  icon: string;
  url: string;
};

export const CategorySelector = ({ categories }: CategorySelectorProps) => {
  return (
    <div className="container px-0 lg:px-4 xl:px-0">
      <div
        className={c(
          '-mt-4 flex gap-10 overflow-x-auto px-4 py-4 lg:justify-center lg:gap-6 lg:overflow-x-visible',
          {
            ['lg:flex-wrap']: categories.length > 10,
          }
        )}
      >
        {categories.map((category, index) => (
          <Fragment key={category.value}>
            {/* insert break to evenly break items between two flex rows when over 10 items */}
            {categories.length > 10 &&
              index === Math.ceil(categories.length / 2) && (
                <div className="-m-4 h-0 basis-full"></div>
              )}
            <div className="w-24 flex-shrink-0 lg:flex-shrink">
              <a
                className="focus-outline group flex flex-col items-center rounded"
                href={category.url}
              >
                <DynamicIcon
                  iconName={category.icon}
                  size={56}
                  className={`lg:duration-400 mb-5 scale-143 rounded-full p-4 lg:mb-4 lg:scale-100 lg:p-3.5 lg:transition lg:ease-in-out lg:group-hover:scale-143 ${
                    category.url.includes('on_sale')
                      ? 'bg-red-200 fill-red-600'
                      : 'bg-blue-200 fill-blue'
                  }`}
                />
                <div
                  className={`break-keep text-center text-sm font-semibold uppercase tracking-wider ${
                    category.url.includes('on_sale')
                      ? 'text-red-600'
                      : 'text-blue'
                  }`}
                >
                  {category.value}
                </div>
              </a>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
