import c from 'classnames';
import React from 'react';
import { IconButton } from '../Button/IconButton';
import { FormErrorMessage } from './FormErrorMessage';

type InputProps = {
  type: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  autocomplete?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  required?: boolean;
  accessibleLabel: string;
  clearInputLabel?: string;
  error?: string;
  className?: string;
};

export const Input = (props: InputProps) => {
  const {
    id,
    value,
    className = '',
    accessibleLabel,
    error,
    onChange,
    onClear,
    clearInputLabel,
    ...rest
  } = props;
  return (
    <div className={className}>
      <label htmlFor={id} className="sr-only">
        {accessibleLabel}
      </label>
      <div className="relative">
        <input
          {...rest}
          value={value}
          aria-invalid={Boolean(error)}
          id={id}
          aria-describedby={error ? `${id}-error` : undefined}
          className={c(
            'body-small focus-outline w-full rounded-lg border border-grey-700 bg-transparent p-3 pr-10 text-white placeholder:font-medium placeholder:text-grey-600 focus:border-transparent',
            {
              ['border-0 outline outline-2 outline-red-500']: Boolean(error),
            }
          )}
          onChange={onChange}
        />
        <div className="absolute right-4 top-3 mt-px flex items-center gap-1">
          {value && onClear && (
            <IconButton
              iconName="X"
              ariaLabel={clearInputLabel || 'Clear input'}
              className="h-5 w-5 text-grey-400 hover:text-grey-400"
              onClick={onClear}
            />
          )}
        </div>
      </div>
      <div aria-live="polite">
        {error && <FormErrorMessage inputId={id} errorMessage={error} />}
      </div>
    </div>
  );
};
