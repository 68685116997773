import { SVGProps } from 'react';

export const SelectArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="16"
    fill="none"
    stroke="none"
    viewBox="0 0 256 256"
    {...props}
  >
    <rect width="256" height="256" fill="none" stroke="none" />
    <polyline points="208 96 128 176 48 96" fill="none" strokeWidth="50" />
  </svg>
);
