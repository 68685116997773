'use client';

import c from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { elementIsNotVisible } from 'utils';
import { GalleryControls } from './GalleryControls';
import GallerySlide, { GallerySlideData } from './GallerySlide';
import { GalleryThumbnailButton } from './GalleryThumbnailButton';

export type GalleryProps = {
  position: 'details' | 'fullscreen-preview';
  slides: GallerySlideData[];
  className?: string;
  hasCookieConsent: boolean;
  microcopy: {
    galleryAriaLabel: string;
    previousAriaLabel: string;
    nextAriaLabel: string;
    videoCookieOverlayMicrocopy: {
      description: string;
      buttonLabel: string;
    };
  };
};

export const Gallery = ({
  position = 'details',
  slides,
  microcopy,
  className,
  hasCookieConsent,
}: GalleryProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scroll thumbnail buttons horizontally with the scroll wheel on mobile screen sizes.
    // on desktop sizes, vertical scroll will already work
    const scrollThumbnailButtonsListener = (event) => {
      const TABLET_SCREEN_BOUNDARY = 1024;
      if (
        thumbnailContainerRef?.current &&
        window.innerWidth < TABLET_SCREEN_BOUNDARY
      ) {
        event.preventDefault();
        thumbnailContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    thumbnailContainerRef.current?.addEventListener(
      'wheel',
      scrollThumbnailButtonsListener
    );

    return () => {
      thumbnailContainerRef.current?.removeEventListener(
        'wheel',
        scrollThumbnailButtonsListener
      );
    };
  }, []);

  if (slides.length === 0) {
    return null;
  }

  const handleSetCurrentSlide = (index: number) => {
    const thumbnailButton = document.getElementById(
      `gallery-thumbnail-${index}`
    );

    // scroll to the thumbnail button if it is not visible
    if (
      thumbnailButton &&
      thumbnailContainerRef?.current &&
      elementIsNotVisible(thumbnailButton, thumbnailContainerRef.current)
    ) {
      const OFFSET_FOR_SPACING = 4;
      thumbnailContainerRef.current?.scrollTo({
        top: thumbnailButton.offsetTop - OFFSET_FOR_SPACING,
        left: thumbnailButton.offsetLeft - OFFSET_FOR_SPACING,
      });
    }

    setCurrentSlide(index);
  };

  const focusSlideContent = (index: number) => {
    // focus current slide
    const slide = document.getElementById(`gallery-slide-${index}`);
    if (slide) {
      slide.tabIndex = -1;
      // slide can't be focused while it has visibility: none CSS rule, setTimeout waits one tick for the slide to be visible
      setTimeout(() => {
        slide.focus();
      }, 0);
    }
  };

  const nextSlide = () => {
    handleSetCurrentSlide(
      currentSlide === slides.length - 1 ? 0 : currentSlide + 1
    );
  };

  const previousSlide = () => {
    handleSetCurrentSlide(
      currentSlide === 0 ? slides.length - 1 : currentSlide - 1
    );
  };

  return (
    <section
      role="group"
      aria-label={microcopy.galleryAriaLabel}
      className={c('relative grid w-full grid-cols-8 gap-x-4', className)}
    >
      <div
        ref={thumbnailContainerRef}
        className="light-custom-scrollbar hide-scrollbar relative order-1 col-span-8 mt-4 h-full min-h-14 overflow-auto lg:order-none lg:col-span-1 lg:mt-0"
      >
        <ul className="absolute flex gap-3 pl-1 pr-1 pt-1 lg:w-full lg:flex-col">
          {slides.map((slide, index) => (
            <li key={`${slide.src}-${index}`}>
              <GalleryThumbnailButton
                id={`gallery-thumbnail-${index}`}
                currentSlide={currentSlide}
                setCurrentSlide={() => {
                  handleSetCurrentSlide(index);
                  focusSlideContent(index);
                }}
                slide={slide}
                index={index}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="col-span-8 flex aspect-video flex-col lg:col-span-7 lg:col-start-2">
        <GalleryControls
          currentSlide={currentSlide}
          totalSlides={slides.length}
          nextSlide={nextSlide}
          previousSlide={previousSlide}
          previousAriaLabel={microcopy.previousAriaLabel}
          nextAriaLabel={microcopy.nextAriaLabel}
        />
        <div className="flex h-full w-full overflow-hidden">
          {slides.map((slide, index) => (
            <GallerySlide
              key={`${slide.src}-${index}`}
              id={`gallery-slide-${index}`}
              isHidden={currentSlide !== index}
              style={
                index === 0 ? { marginLeft: `-${currentSlide * 100}%` } : {}
              }
              position={position}
              slide={slide}
              hasCookieConsent={hasCookieConsent}
              videoCookieOverlayMicrocopy={
                microcopy.videoCookieOverlayMicrocopy
              }
            />
          ))}
        </div>
      </div>
    </section>
  );
};
