import c from 'classnames';
import { Duration } from 'date-fns';
import {
  ImageFocalPoint,
  Image as ImageType,
  Video as VideoType,
} from '../../lib/types';
import { CTA } from '../Button/types';
import { CountdownTiles } from '../CountdownTiles/CountdownTiles';
import { FocalPointImage } from '../FocalPointImage/FocalPointImage';
import { LinkButton } from '../Link/LinkButton';
import { BackgroundVideo } from '../Video/BackgroundVideo';
import { HomepageHeroEmbed } from './HomepageHeroEmbed';
import { alignmentStyles } from './alignmentStyles';

type HomepageHeroProps = {
  subHeader?: string | null;
  title: string;
  description: string;
  countdownDuration?: Duration;
  countdownLabels?: Record<string, string>;
  image?: ImageType;
  imageFocusArea?: ImageFocalPoint | null;
  video?: VideoType;
  embeddedVideo?: VideoType;
  hasCookieConsent?: boolean;
  align?: 'left' | 'right' | 'center';
  actions: CTA[];
  onCtaClick: (ctaLabel: string) => void;
};

const mainContainerClasses =
  'bg-black relative xl:overflow-hidden xl:rounded-3xl h-full antialiased xl:max-h-116 min-h-110';
const centerAlignmentClasses = 'block xl:flex xl:justify-center';
const gridClasses = 'xl:main-grid p-0';
const imageContainerClasses =
  'col-span-4 row-start-1 relative overflow-hidden h-40 md:h-[427px] lg:h-[486px]';
const videoContainerClasses =
  'col-span-4 row-start-1 relative overflow-hidden aspect-video xl:aspect-auto h-auto w-full';
const textContainerClasses =
  'py-12 md:pt-16 xl:py-12 px-4 md:px-12 xl:px-0 relative md:container xl:grid xl:grid-cols-5 xl:items-center text-center';
const textInnerContainerClasses = 'md:grid md:grid-cols-12 md:gap-x-4 xl:block';
const subHeaderClasses = 'caption text-white mb-4 md:col-start-1 md:col-end-8';
const headlineClasses =
  'homepage-hero text-balance text-white md:col-start-1 md:col-end-8 xl:block';
const descriptionClasses =
  'block body text-white md:mt-0 md:col-start-1 md:col-end-8 xl:block mt-4 md:mt-0 lg:mt-5';
const ctaButtonsClasses =
  'flex flex-col gap-2 md:col-start-9 md:col-end-13 md:row-start-1 md:row-end-3 lg:gap-6 mt-4 md:mt-0 xl:mt-8 lg:flex-row items-center md:items-start lg:items-center';
const countdownContainerClasses =
  'pointer-events-none h-18 md:h-20 xl:h-24 mb-8 xl:mb-0 mt-5 md:absolute xl:static md:-top-16 md:left-0 md:flex justify-center md:w-full';

export const HomepageHero = ({
  subHeader,
  title,
  description,
  countdownDuration,
  countdownLabels,
  image,
  imageFocusArea,
  video,
  embeddedVideo,
  align = 'left',
  hasCookieConsent = false,
  actions,
  onCtaClick,
}: HomepageHeroProps) => {
  const adjustedAlignment =
    embeddedVideo && align === 'center' ? 'left' : align;
  const { imageStyle, text, container, ctaClasses, countdownContainer } =
    alignmentStyles[adjustedAlignment];

  return (
    <div className="group xl:container">
      <div
        className={c(
          mainContainerClasses,
          adjustedAlignment === 'center' ? centerAlignmentClasses : gridClasses
        )}
      >
        <div
          className={c(
            video || embeddedVideo
              ? videoContainerClasses
              : imageContainerClasses,
            imageStyle
          )}
        >
          {image && !video && !embeddedVideo && (
            <FocalPointImage
              testId="homepage-hero-image"
              image={image.url}
              alt={image.alt || ''}
              width={image.width!}
              height={image.height!}
              focalPoint={imageFocusArea?.focalPoint}
            />
          )}
          {video && !embeddedVideo && (
            <BackgroundVideo
              title={video.title}
              fallbackImage={video.fallbackImage}
              url={video.url}
              description={video.description}
              /* countdown makes it so the video doesn't have the right aspect ratio on desktop */
              objectFit={
                countdownDuration && adjustedAlignment !== 'center'
                  ? 'contain'
                  : 'cover'
              }
            />
          )}
          {embeddedVideo && (
            <HomepageHeroEmbed
              hasCookieConsent={hasCookieConsent}
              video={embeddedVideo}
            />
          )}
        </div>

        <div className={c(textContainerClasses, text)}>
          <div className={c(textInnerContainerClasses, container)}>
            {subHeader && (
              <p
                data-test="homepage-hero-subheader"
                className={subHeaderClasses}
              >
                {subHeader}
              </p>
            )}
            <h2 data-test="homepage-hero-title" className={headlineClasses}>
              {title}
            </h2>
            <span
              data-test="homepage-hero-description"
              className={descriptionClasses}
            >
              {description}
            </span>

            {countdownDuration && (
              <div className={c(countdownContainerClasses, countdownContainer)}>
                <CountdownTiles
                  unitLabels={countdownLabels}
                  duration={countdownDuration}
                />
              </div>
            )}
            <div className={c(ctaButtonsClasses, ctaClasses)}>
              {actions.map((action) => (
                <LinkButton
                  href={action.url}
                  openLinkInNewTab={action.openInNewTab}
                  label={action.label}
                  size="medium"
                  style={action.style}
                  className="text-white"
                  isDarkMode={true}
                  key={action.label}
                  data-test={`homepage-hero-cta-${action.label.replace(' ', '-').toLowerCase()}`}
                  onClick={() => onCtaClick(action.label)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
