'use client';
import c from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import { ReactFocusOnProps } from 'react-focus-on/dist/es5/types';
import { useAnimateVisibility } from '../../lib/hooks/useAnimateVisibility';
import { IconButton } from '../Button/IconButton';

type NavigationModalProps = {
  closeLabel: string;
  isActive: boolean;
  portalDomNode?: HTMLElement | null;
  closeModal: () => void;
  children: ReactNode;
} & ReactFocusOnProps;

export const FullscreenModal = ({
  closeLabel,
  closeModal,
  portalDomNode,
  children,
  isActive = false,
  ...rest
}: NavigationModalProps) => {
  const { isAnimatedIn, isAnimatedOut, isAnimating } =
    useAnimateVisibility(isActive);

  const modalClassNames = c(
    'w-full h-full bg-black fixed top-0 left-0 z-modal transition duration-300',
    {
      'opacity-1 ': isAnimatedIn,
      'opacity-0': isAnimating,
    }
  );

  // stop rendering modal when both parent says modal is no longer active, and animation is complete
  if (isAnimatedOut) {
    return null;
  }

  return createPortal(
    <FocusOn
      returnFocus
      onEscapeKey={closeModal}
      onClickOutside={closeModal}
      {...rest}
    >
      <div role="dialog" aria-modal="true" className={modalClassNames}>
        <div className="flex justify-end bg-black">
          <IconButton
            data-test="modal-close-button"
            iconName="X"
            ariaLabel={closeLabel}
            onClick={closeModal}
            className="mx-2 my-3 w-[2.25rem] p-2"
            iconClassName="fill-grey-400"
          />
        </div>
        {children}
      </div>
    </FocusOn>,
    portalDomNode ?? document.body
  );
};
