'use client';
import { useState } from 'react';
import { interpolateStringWithData } from 'utils';
import { Video as VideoType } from '../../lib/types';
import { VideoThumbnailButton } from './VideoThumbnailButton';

type VideoLibraryProps = {
  videos: VideoType[];
  onVideoSelect: (video: VideoType) => void;
  ariaLabel: string;
};

export const VideoLibrary = ({
  videos,
  onVideoSelect,
  ariaLabel,
}: VideoLibraryProps) => {
  const [activeVideo, setActiveVideo] = useState<VideoType | null>(videos[0]);

  const handleVideoSelect = (video: VideoType) => {
    setActiveVideo(video); // Update the active video
    onVideoSelect(video); // Propagate the selected video to parent
  };

  return (
    <>
      {videos.map((video) => (
        <VideoThumbnailButton
          key={video.url}
          url={video.url}
          ariaLabel={interpolateStringWithData(ariaLabel, {
            title: video.title ?? '',
          })}
          thumbnail={video.thumbnail}
          isActive={activeVideo === video}
          onClick={() => handleVideoSelect(video)}
        />
      ))}
    </>
  );
};
