import c from 'classnames';
import { DynamicIcon } from 'phosphor-icons/react';

type IconWithCountProps = {
  iconName: string;
  count: number;
  maxCount?: number;
  iconSize?: number;
  countStyles?: string;
};

const MAX_COUNT_DEFAULT = 99;
const ICON_SIZE_DEFAULT = 20;

export const IconWithCount = ({
  iconName,
  iconSize = ICON_SIZE_DEFAULT,
  count,
  maxCount = MAX_COUNT_DEFAULT,
  countStyles = 'bg-blue font-semibold text-white',
}: IconWithCountProps) => {
  const renderCount = () => {
    if (count === 0) {
      return null;
    }

    const countOutput = count > maxCount ? `${maxCount}+` : count;
    return (
      <div
        className={c(
          'pointer-events-none absolute right-0.5 top-0.5 flex h-[18px] w-[18px] items-center justify-center rounded-full text-[9px]',
          countStyles
        )}
        data-test="count"
      >
        {countOutput}
      </div>
    );
  };

  return (
    <div
      className="relative flex h-full w-full items-center justify-center"
      data-test="icon-with-count"
    >
      {renderCount()}
      <DynamicIcon iconName={iconName} size={iconSize} />
    </div>
  );
};
