import { useEffect, useState } from 'react';

export default function useDoesElementOverflow<T extends HTMLElement>(
  elementRef: React.RefObject<T>
): boolean {
  const [elementOverflows, setDoesElementOverflow] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      setDoesElementOverflow(doesElementOverflow(elementRef.current));
    }
  }, [elementRef]);

  return elementOverflows;
}

function doesElementOverflow(element: HTMLElement | null) {
  if (!element) {
    return false;
  }
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
