import Image from 'next/image';

export type SubCategorySelectorItemProps = {
  image: string;
  label: string;
  url: string;
};

export const SubCategorySelectorItem = ({
  image,
  label,
  url,
}: SubCategorySelectorItemProps) => {
  return (
    <a
      href={url}
      data-test="sub-category-selector-item"
      className="focus-outline block cursor-pointer rounded-lg [&_img]:hover:brightness-100"
      aria-label={label}
    >
      <div className="relative mb-2 aspect-3/2 w-full">
        <Image
          data-test="sub-category-selector-item-image"
          src={image}
          className="rounded-lg object-cover brightness-75 transition-all"
          fill
          alt=""
        />
      </div>
      <div className="caption flex justify-center break-words text-center text-sm text-blue">
        {label}
      </div>
    </a>
  );
};
