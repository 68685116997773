import { CountdownTile } from './CountdownTile';

type Duration = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export type CountdownTileUnitLabels = Record<string, string>;

type CountdownTilesProps = {
  duration: Duration;
  unitLabels?: CountdownTileUnitLabels;
};

const toReadableLabel = (
  duration: Duration,
  unitLabels?: CountdownTileUnitLabels
) => {
  const output: string[] = [];
  Object.entries(duration).forEach(([unit, value]) => {
    const label = unitLabels ? unitLabels[unit] : unit;
    output.push(`${value} ${label}`);
  });
  return output.join(', ');
};

export const CountdownTiles = ({
  duration,
  unitLabels,
}: CountdownTilesProps) => {
  return (
    <div
      className="inline-grid h-full grid-flow-col gap-3 xl:gap-4"
      data-test="tiles-wrapper"
      aria-label={toReadableLabel(duration, unitLabels)}
      /**
       * We need to suppress hydration warnings for this element and its children
       * to avoid seeing a warning where the initial timestamp from the server
       * is one second behind.
       * See https://react.dev/reference/react-dom/client/hydrateRoot#suppressing-unavoidable-hydration-mismatch-errors
       * and https://nextjs.org/docs/messages/react-hydration-error
       */
      suppressHydrationWarning
    >
      {Object.entries(duration)
        .filter(([_, value]) => value !== undefined)
        .map(([unit, value]) => (
          <CountdownTile
            key={unit}
            value={value}
            unit={unit}
            label={unitLabels ? unitLabels[unit] : undefined}
          />
        ))}
    </div>
  );
};
