'use client';
import { FloatingArrow, FloatingContext } from '@floating-ui/react';
import c from 'classnames';
import { Ref } from 'react';

type TooltipArrowProps = {
  arrowRef?: Ref<any>;
  context: FloatingContext;
  className?: string;
};
export const TooltipArrow = ({
  arrowRef,
  context,
  className = '',
}: TooltipArrowProps) => {
  return (
    <FloatingArrow
      ref={arrowRef}
      context={context}
      className={c('fill-grey-700', className)}
    />
  );
};
