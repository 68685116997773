import { Locale, formatDisplayableCurrency } from 'utils';

type PriceProps = {
  value: string;
  currency?: string;
};

export const Price = ({ value, currency }: PriceProps) => {
  return (
    <>
      {formatDisplayableCurrency(parseFloat(value), Locale.ENGLISH, currency)}
    </>
  );
};
