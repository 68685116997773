import xss from 'xss';
import { DEFAULT_LOCALE } from './locale';

/**
 * Replaces {{variable}} in a string
 *
 * @param input The string to interpolate
 * @param data The variables to replace, replaces {{key}} with the value of data[key]
 */
export const interpolateStringWithData = (
  input: string,
  data: Record<string, string | number>
) => {
  const dataStringified = Object.entries(data).reduce((previous, current) => {
    const [key, value] = current;
    if (typeof value === 'number') {
      return {
        ...previous,
        [key]: value.toString(),
      };
    }
    return {
      ...previous,
      [key]: value,
    };
  }, {});

  return input.replace(/\{\{(\w+)\}\}/g, (match, variable) => {
    return dataStringified[variable] || match;
  });
};

/**
 * Replaces [text](link) patterns with HTML <a> tags, ensuring safety from script tags and javascript: URLs.
 * Escapes any HTML tags in the input to prevent XSS attacks.
 *
 * @param input The string containing potential [text](link) patterns
 * @returns The string with links replaced by <a href="...">text</a>, and HTML tags escaped
 */
export const replaceLinks = (input: string): string => {
  // Escape the entire input to prevent XSS attacks
  let escapedInput = xss(input);

  // Replace only well-formed markdown links with <a> tags
  escapedInput = escapedInput.replace(
    /\[([^\[\]]+)\]\((https?:\/\/[^\s)]+|\/[^\s)]+)\)/g, // Match only well-formed [text](url) patterns
    (_, text, url) => {
      // Prevent javascript: URLs by rejecting them
      if (/^javascript:/i.test(url)) {
        return text; // Return escaped text without the link
      }

      // Return the safe <a> tag for valid URLs
      return `<a href="${url}">${text}</a>`;
    }
  );

  return escapedInput;
};

/**
 * Converts a name into up to 2 initials.
 * If one word passed in, returns first letter.
 * If two words, returns first letter of each word.
 * If multiple words, returns first letter of the first and last words.
 *
 * @param name
 * @returns up to 2 initials of the passed in name
 */
export const toAvatarInitials = (name: string) => {
  const names = name.trim().split(' ');
  const firstInitial = names[0].slice(0, 1).toUpperCase();
  const lastInitial =
    names.length > 1 ? names[names.length - 1].slice(0, 1).toUpperCase() : '';
  return `${firstInitial}${lastInitial}`;
};

/**
 * Inserts an underscore between a lower case and uppercase letter and converts the string to uppercase
 */
export const toUpperSnakeCase = (input: string) => {
  return input.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
};

/**
 * Checks the input to determine if it's a valid URL
 * @param input
 * @returns
 */
export const isValidImageUrl = (input: string) => {
  let url: URL;

  try {
    url = new URL(input);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const stringToBoolean = (input: string): boolean => {
  return input === 'true';
};

/**
 * A function to be used to pluralize texts coming from the CMS.
 * @param key The property name in the entry object WITHOUT the form suffix. e.g. 'subtotal' instead of 'subtotalOne'
 * @param entry The object containing the pluralized text fields
 * @param count The number to determine the plural form
 * @param locale The locale to use for pluralization, uses the default locale if not provided
 * @param ordinal Whether to use ordinal pluralization. e.g. '1st', '2nd', '3rd', '4th'
 * @returns the pluralized text with count interpolated
 */
export function pluralize(
  key: string,
  entry: object,
  count: number,
  locale: string = DEFAULT_LOCALE,
  ordinal: boolean = false
) {
  const pr = new Intl.PluralRules(locale, {
    type: ordinal ? 'ordinal' : 'cardinal',
  });
  // e.g. 'one', 'other'
  const form = pr.select(count);
  const text = buildText(key, entry, form, count);

  if (fallbackText(text, locale)) {
    return pluralize(key, entry, count, DEFAULT_LOCALE, ordinal);
  }

  return text;
}

function buildText(key: string, entry: object, form: string, count: number) {
  const text = entry[key + capitalizeFirstLetter(form)];
  return text.replace('{{count}}', count);
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * If the text is not in the default locale,
 * then we check if the text is ASCII only.
 */
function fallbackText(text: string, locale: string) {
  return locale !== DEFAULT_LOCALE && /^[\u0000-\u007f]*$/.test(text);
}

export const extractDomainFromEmail = (email: string) => {
  if (!email.includes('@')) {
    return '';
  }

  const splitEmail = email.split('@');
  return splitEmail[splitEmail.length - 1];
};

/**
 * Replaces spaces with underscore and removes all special characters so titles can be used as IDs.
 * Keeps underscores as they are used to separate words.
 */
export const formatTitleForID = (title: string) => {
  return title.replaceAll(' ', '_').replace(/[^a-zA-Z0-9_]/g, '');
};

/**
 * Truncates text at a given length and adds a (configurable) elipsis after the text that has been truncated.
 */
export const truncateText = (text: string, max?: number, elipsis = '...') => {
  const shouldTruncate = max && text.length > max;
  return text.substring(0, max) + (shouldTruncate ? elipsis : '');
};
