export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function isTest() {
  return process.env.NODE_ENV === 'test';
}

export function isNextLocal() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'development';
}

export function isNodeProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isNextProduction() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
}
