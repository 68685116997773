'use client';
import c from 'classnames';
import Image from 'next/image';
import { Play } from 'phosphor-icons';
import ReactPlayer from 'react-player';
import { useIsClient } from '../../lib/hooks/useIsClient';

type VideoThumbnailButtonProps = {
  url: string;
  thumbnail?: string;
  ariaLabel: string;
  isActive: boolean;
  onClick: () => void;
};

const PlayOverlay = () => (
  <div className="absolute flex h-full w-full items-center justify-center bg-black/40 text-white">
    <Play size={32} weight="fill" />
  </div>
);

export const VideoThumbnailButton = ({
  url,
  thumbnail,
  isActive,
  ariaLabel,
  onClick,
}: VideoThumbnailButtonProps) => {
  const isClient = useIsClient();

  return (
    <button
      data-test="livestream-video-thumbnail"
      className={c(
        'relative mb-4 aspect-3/2 w-8/12 flex-none overflow-hidden rounded-md bg-grey-200 outline-2 md:aspect-video md:w-full',
        {
          'outline outline-offset-2 outline-blue': isActive && isClient,
          'hover:outline hover:outline-[3px] hover:outline-blue focus:outline focus:outline-[3px] focus:outline-blue':
            !isActive,
        }
      )}
      onClick={onClick}
      aria-current={isActive}
      aria-label={ariaLabel}
    >
      {isClient && (
        <ReactPlayer
          url={url}
          light={
            thumbnail ? (
              <Image src={thumbnail} alt={ariaLabel} layout="fill" />
            ) : (
              true
            )
          }
          width="100%"
          height="100%"
          style={{ pointerEvents: 'none' }}
          playIcon={<PlayOverlay />}
          previewTabIndex={-1}
        />
      )}
    </button>
  );
};
