import { Button } from '../../Button/Button';

type FormActionProps = {
  cancel: () => void;
  submit: () => void;
  isDisabled: boolean;
  cancelLabel?: string;
  submitLabel?: string;
};

export const FormActions = ({
  cancel,
  submit,
  isDisabled,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
}: FormActionProps) => {
  return (
    <div className="mb-9 mt-6 flex justify-end">
      <Button
        type="button"
        onClick={cancel}
        label={cancelLabel}
        style="secondary"
        className="mr-4"
      />
      <Button
        type="submit"
        onClick={submit}
        label={submitLabel}
        disabled={isDisabled}
      />
    </div>
  );
};
