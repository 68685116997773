import { isValueInStringEnum } from './typeguards';

export enum Locale {
  ENGLISH = 'en-US',
  CHINESE = 'zh-CN',
  KOREAN = 'ko-KR',
  JAPANESE = 'ja-JP',
}

export const LOCALE_TO_DISPLAY_NAME = {
  [Locale.ENGLISH]: 'English',
  [Locale.CHINESE]: '简体中文',
  [Locale.JAPANESE]: '日本語',
  [Locale.KOREAN]: '한국어',
};

export const DEFAULT_LOCALE = Locale.ENGLISH;

export const SUPPORTED_LOCALES = [
  Locale.ENGLISH,
  Locale.CHINESE,
  Locale.KOREAN,
  Locale.JAPANESE,
].map((locale) => locale.toString());

export function getLocaleFromUrl(): Locale {
  if (typeof window === 'undefined') {
    return DEFAULT_LOCALE;
  }

  const localePath = window.location.pathname.split('/')[1];
  const isLocale = isValueInStringEnum(Locale);

  return isLocale(localePath) ? localePath : DEFAULT_LOCALE;
}

export const startsWithLocalizedPath = (
  requestPath: string,
  targetPath: string
) => {
  if (requestPath.startsWith(targetPath)) {
    return true;
  }

  let output = false;
  SUPPORTED_LOCALES.forEach((locale) => {
    if (requestPath.startsWith(`/${locale}${targetPath}`)) {
      output = true;
    }
  });

  return output;
};

export const equalsLocalizedPath = (
  requestPath: string,
  targetPath: string
) => {
  if (requestPath === targetPath) {
    return true;
  }

  let output = false;
  SUPPORTED_LOCALES.forEach((locale) => {
    if (requestPath === `/${locale}${targetPath}`) {
      output = true;
    }
  });

  return output;
};

export const isHomepagePath = (requestPath: string) => {
  if (requestPath === '/') {
    return true;
  }

  let output = false;
  SUPPORTED_LOCALES.forEach((locale) => {
    if (requestPath === `/${locale}`) {
      output = true;
    }
  });

  return output;
};

export const getWithoutLocale = (input: string) => {
  const LOCALE_CHAR_END_INDEX = 6;

  let hasValidLocale = false;
  SUPPORTED_LOCALES.forEach((locale) => {
    if (input.slice(0, LOCALE_CHAR_END_INDEX) === `/${locale}`) {
      hasValidLocale = true;
    }
  });

  if (hasValidLocale) {
    return input.slice(LOCALE_CHAR_END_INDEX);
  }
  return input;
};

export const getSafeLocale = (locale: string) => {
  if (!SUPPORTED_LOCALES.includes(locale)) {
    return DEFAULT_LOCALE;
  }
  return locale;
};
