import { CookieConsentOverlay } from '../CookieConsentOverlay/CookieConsentOverlay';

type ThirdPartyIframeProps = {
  title?: string;
  embedUrl: string;
  cookieConsent: boolean;
  cookieOverlayMicrocopy?: {
    description: string;
    buttonLabel: string;
  };
  isVisible?: boolean;
};

export const ThirdPartyIframe = ({
  title,
  embedUrl,
  cookieConsent = false,
  cookieOverlayMicrocopy = {
    description:
      'This content is hosted by a third party provider that does not allow video views without acceptance of Targeting Cookies. Please set your cookie preferences for Targeting Cookies to yes if you wish to view videos from these providers.',
    buttonLabel: 'Open Cookie Settings',
  },
  isVisible = true,
}: ThirdPartyIframeProps) => {
  return (
    <div className="h-full w-full">
      {!cookieConsent && <CookieConsentOverlay {...cookieOverlayMicrocopy} />}
      {/*
       * use isVisible so we can instruct the component to only render the third party element if it should actually be rendered on the screen. (ie: GallerySlide, only render iframe when slide is actually selected)
       * This is a bit of a hack so that we can have the CookieConsent button available in the DOM when the One Trust script initializes,
       * and so that we don't load extra third party code unless its actually needed */}
      {cookieConsent && isVisible && (
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          title={title ?? ''}
          loading="lazy"
          allowFullScreen
        />
      )}
    </div>
  );
};
