import c from 'classnames';
import Image from 'next/image';
import { Image as ImageType } from '../../lib/types';
import { ButtonStyle } from '../Button/types';
import { Label } from '../Label/Label';
import { LinkButton } from '../Link/LinkButton';

export type ContentCardProps = {
  title?: string;
  id: string;
  description: string;
  image?: ImageType;
  cta?: {
    label: string;
    url: string;
  };
  ctaStyle?: ButtonStyle;
  label?: string;
  isDarkTheme?: boolean;
  onCtaClick: (ctaLabel: string) => void;
};

export const ContentCard = ({
  title,
  id,
  description,
  image,
  cta,
  ctaStyle = 'secondary',
  label,
  isDarkTheme = false,
  onCtaClick,
}: ContentCardProps) => {
  return (
    <div className={c('h-full', { dark: isDarkTheme })}>
      <article className="flex h-full flex-col rounded-3xl border border-grey-200 bg-white dark:border-grey-800 dark:bg-grey-900 lg:hover:shadow-card dark:lg:hover:shadow-cardDark">
        {image && (
          <div className="relative aspect-3/2 overflow-hidden rounded-3xl rounded-b-none lg:mx-6 lg:mt-6 lg:rounded-lg lg:rounded-b-lg">
            <Image
              src={image.url}
              alt={image.alt ?? ''}
              fill
              sizes="(max-width: 768px) 100vw, 33vw"
              className="object-cover"
            />
          </div>
        )}
        <div
          className={c('inner flex grow flex-col items-baseline px-6 pb-8', {
            'pt-6': image,
            'pt-8': !image,
          })}
        >
          {label && (
            <Label
              isDarkTheme={isDarkTheme}
              appearance="highlight"
              text={label}
              className="mb-5 lg:mb-6"
            />
          )}
          {title && (
            <h2
              className="header-xsmall mb-6 inline-block dark:text-white"
              id={id}
            >
              {title}
            </h2>
          )}
          <div className="body-small text-grey-600 dark:text-grey-300">
            {description}
          </div>
          {cta && (
            <div className="mt-auto pt-8">
              <LinkButton
                size="small"
                label={cta.label}
                className="inline-block"
                href={cta.url}
                style={ctaStyle}
                aria-describedby={id}
                isDarkMode={isDarkTheme}
                onClick={() => onCtaClick(cta.label)}
              />
            </div>
          )}
        </div>
      </article>
    </div>
  );
};
