'use client';
import { useEffect, useState } from 'react';

export type AnimationStates = {
  isAnimatedIn: boolean;
  isAnimating: boolean;
  isAnimatedOut: boolean;
};

export const DEFAULT_TIMEOUT_MS = 300;

/** Use to animate an element from appearance/disappearance */
export const useAnimateVisibility = (
  isActive: boolean,
  animationDurationMs = DEFAULT_TIMEOUT_MS
): AnimationStates => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isActive && !isVisible) {
      // animate in
      setIsVisible(true);
    } else if (!isActive && isVisible) {
      // animate out
      setTimeout(() => setIsVisible(false), animationDurationMs);
    }
  }, [isActive, isVisible, animationDurationMs]);

  return {
    isAnimatedIn: isActive && isVisible,
    isAnimating: !(isActive && isVisible),
    isAnimatedOut: !isActive && !isVisible,
  };
};
