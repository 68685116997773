'use client';
import {
  FloatingFocusManager,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';
import c from 'classnames';
import { CaretDown, CaretUp } from 'phosphor-icons';
import React, { useRef, useState } from 'react';
import { formatTitleForID } from 'utils';
import { FormErrorMessage } from '../Form/FormErrorMessage';

export type DropdownOption = {
  label: string;
  value: string;
};

export type DropdownProps = {
  id: string;
  options: DropdownOption[];
  selectedOption: DropdownOption | null;
  onSelect: (option: DropdownOption) => void;
  isDarkTheme?: boolean;
  /**
   * aria tags should be used to provide extra context to screen reader users about functionality of the popup
   * ariaLabelledBy: if there is another visible element on the page that acts as a label for the dropdown, use element id(s)
   */
  ariaLabelledBy?: string;
  /**
   * ariaLabel: if there is no visible label that provides context for the dropdowns functionality, can use a text description.
   * Be aware that changing this will override whatever content is in the button, so you should make sure to use the Dropdown's value as part of the label.
   */
  ariaLabel?: string;
  /* If its possible to have no value selected, provide a placeholder */
  noSelectedOptionPlaceholder?: string;
  /* maximum height in pixels for the dropdown, content will scroll if it goes past */
  maxDropdownHeight?: number;
  dropdownStyle?: 'default' | 'newsletter';
  className?: string;
  error?: string;
};

const buttonClassNames = (isOpen: boolean, dropdownStyle: string) =>
  c(
    'flex justify-between py-3 pl-4 pr-2 gap-x-2 w-full border border-grey-400 dark:border-grey-700 font-medium focus-outline rounded-lg',
    {
      'bg-white text-grey-700 dark:bg-grey-900 dark:text-white': isOpen,
      'bg-grey-100 text-grey-600 hover:text-grey-700 dark:bg-grey-800 dark:text-grey-400 dark:hover:text-grey-300':
        !isOpen,
      '!bg-transparent text-white hover:text-white':
        dropdownStyle === 'newsletter',
      'text-sm': dropdownStyle === 'default',
      'body-small': dropdownStyle === 'newsletter',
    }
  );

const optionClassNames = (isSelected: boolean) =>
  c(
    'cursor-pointer block focus-outline rounded-md m-1 p-2 text-sm truncate transition',
    {
      'font-bold text-black bg-grey-100 dark:text-grey-300 dark:bg-black':
        isSelected,
      'text-grey-700 font-medium hover:text-black bg-white hover:bg-grey-200 dark:text-grey-300 dark:bg-grey-800 dark:hover:bg-grey-900 dark:hover:text-grey-300':
        !isSelected,
    }
  );

const scrollbarStyles = `
  /* Firefox, Chrome */
  .dropdown-listbox {
    scrollbar-width: thin;
    scrollbar-color: #E6E6E6 white;
  }

  /* Safari */
  .dropdown-listbox::-webkit-scrollbar {
    width: 12px;
  }

  .dropdown-listbox::-webkit-scrollbar-track {
    background: white;
  }

  .dropdown-listbox::-webkit-scrollbar-thumb {
    background-color: #E6E6E6;
    border-radius: 20px;
    border: 3px solid white;
  }

  .dark .dropdown-listbox {
    scrollbar-color: black #262626;
  }

  .dark .dropdown-listbox::-webkit-scrollbar-track {
    background: #262626;
  }

  .dark .dropdown-listbox::-webkit-scrollbar-thumb {
    background-color: black;
    border: 3px solid #262626;
  }
`;

export const Dropdown = ({
  id,
  options,
  selectedOption,
  onSelect,
  noSelectedOptionPlaceholder = '',
  ariaLabelledBy,
  ariaLabel,
  isDarkTheme = true,
  maxDropdownHeight,
  className = '',
  dropdownStyle = 'default',
  error,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement[]>([]);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, listNavigation]
  );

  const handleSelect = (option: DropdownOption) => {
    toggleDropdown();
    onSelect(option);
  };

  const handleDropdownOptionKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    option: DropdownOption
  ) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleSelect(option);
    } else if (e.key === 'Tab') {
      // close the dropdown when tabbing out
      toggleDropdown();
    }
  };

  return (
    <div
      className={c(
        'relative w-full min-w-32',
        { dark: isDarkTheme },
        className
      )}
      ref={dropdownRef}
    >
      <style>{scrollbarStyles}</style>
      <button
        id={`dropdown-${id}`}
        data-test={`dropdown-${id}`}
        ref={refs.setReference}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-label={ariaLabel}
        // read out the aria-labelledby element's content as well as the currently selected option
        aria-labelledby={
          ariaLabelledBy ? `${ariaLabelledBy} dropdown-${id}` : ''
        }
        className={buttonClassNames(isOpen, dropdownStyle)}
        // While seemingly unecessary, this prop prevents the button from submitting any parent form element
        type="button"
        {...getReferenceProps()}
      >
        <span className="truncate" id={`dropdown-${id}-option-label`}>
          {selectedOption ? selectedOption.label : noSelectedOptionPlaceholder}
        </span>
        {isOpen ? (
          <CaretUp size={20} className="pointer-events-none flex-shrink-0" />
        ) : (
          <CaretDown size={20} className="pointer-events-none flex-shrink-0" />
        )}
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} initialFocus={-1}>
          <div
            // Extra wrapper div with overflow-hidden so that the rounded corners hide the corners of the scrollbar
            className="mt-1 w-full overflow-hidden rounded-lg border border-grey-400 shadow-lg dark:border-grey-700"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
            }}
            {...getFloatingProps()}
          >
            <div
              id={`dropdown-${id}-listbox`}
              role="listbox"
              className="dropdown-listbox w-full overflow-y-auto bg-white dark:bg-grey-800"
              style={{
                maxHeight: maxDropdownHeight,
              }}
              aria-labelledby={ariaLabelledBy}
            >
              {options.map((option, index) => (
                <div
                  key={option.value}
                  id={`dropdown-${id}-option-${index}`}
                  data-test={`dropdown-${id}-option-${formatTitleForID(option.value)}`}
                  role="option"
                  aria-selected={selectedOption?.value === option.value}
                  tabIndex={activeIndex === index ? 0 : -1}
                  className={optionClassNames(
                    selectedOption?.value === option.value
                  )}
                  ref={(node) => {
                    if (node) {
                      listRef.current[index] = node;
                    }
                  }}
                  {...getItemProps()}
                  onClick={() => handleSelect(option)}
                  onKeyDown={(e) => handleDropdownOptionKeyDown(e, option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        </FloatingFocusManager>
      )}
      <div aria-live="polite">
        {error && <FormErrorMessage inputId={id} errorMessage={error} />}
      </div>
    </div>
  );
};
