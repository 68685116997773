'use client';
import { PlusCircle } from 'phosphor-icons';
import { useRef } from 'react';
import useDoesElementOverflow from '../../lib/hooks/useDoesElementOverflow';
import { ImageFocalPoint, Image as ImageType } from '../../lib/types';
import { Button } from '../Button/Button';
import { CTA } from '../Button/types';
import { FocalPointImage } from '../FocalPointImage/FocalPointImage';
import { Gradient } from '../Images/Effects/Gradient';
import { LinkButton } from '../Link/LinkButton';
import { Modal } from '../Modal/Modal';
import { useModal } from '../Modal/useModal';

type CalloutProps = {
  title: string;
  subtitle?: string | null;
  description?: string | null;
  image: ImageType;
  imageFocusArea?: ImageFocalPoint | null;
  action: CTA;
  legalDisclaimer?: string | null;
  readMoreLabel?: string | null;
  closeLabel?: string | null;
  onCtaClick: (ctaLabel: string) => void;
};

export const CalloutSlim = ({
  title,
  subtitle,
  description,
  image,
  imageFocusArea,
  action,
  legalDisclaimer,
  readMoreLabel = 'Read more',
  closeLabel = 'Close',
  onCtaClick,
}: CalloutProps) => {
  const [isActive, toggle] = useModal();
  const disclaimerRef = useRef(null);
  const doesLegalDisclaimerOverflow = useDoesElementOverflow(disclaimerRef);
  return (
    <div className="main-grid">
      <div className="relative col-span-12 grid grid-cols-12 items-center gap-x-4 overflow-hidden rounded-xl px-4 py-12 md:col-span-12 md:px-0 lg:py-16">
        <FocalPointImage
          image={image.url}
          alt={image.alt || ''}
          width={image.width!}
          height={image.height!}
          focalPoint={imageFocusArea?.focalPoint}
        />
        <div className="z-10 col-span-12 md:col-span-7 md:col-start-2">
          {subtitle && (
            <span className="caption mb-5 block text-white">{subtitle}</span>
          )}
          <h2 className="header-mid block text-balance text-white">{title}</h2>
          {description && (
            <span className="body mt-5 block text-white">{description}</span>
          )}
        </div>
        <div className="z-10 col-span-12 mt-10 flex md:col-span-4 md:mt-0 md:justify-center">
          <LinkButton
            href={action.url}
            openLinkInNewTab={action.openInNewTab}
            label={action.label}
            size="medium"
            style={action.style}
            key={action.label}
            onClick={() => onCtaClick(action.label)}
          />
        </div>
        <Gradient className="absolute top-0 h-full w-full" />
      </div>
      {legalDisclaimer && (
        <div className="col-span-12 mt-3 flex">
          {doesLegalDisclaimerOverflow ? (
            <button onClick={toggle} className="md:flex md:gap-2 md:truncate">
              <p className="line-clamp-2 text-left text-sm md:line-clamp-none md:truncate">
                {legalDisclaimer}
              </p>
              <div className="flex items-center gap-1">
                <span className="whitespace-nowrap text-sm font-bold">
                  {readMoreLabel}
                </span>
                <PlusCircle className="text-center" size={20} />
              </div>
            </button>
          ) : (
            <p ref={disclaimerRef} className="truncate text-sm">
              {legalDisclaimer}
            </p>
          )}
          <Modal
            title={title}
            closeLabel={closeLabel!}
            size="medium"
            isActive={isActive}
            closeModal={toggle}
            actions={
              <div className="flex justify-end p-6">
                <Button
                  label={closeLabel!}
                  onClick={toggle}
                  style="secondary"
                />
              </div>
            }
          >
            <p className="p-10 pt-6">{legalDisclaimer}</p>
          </Modal>
        </div>
      )}
    </div>
  );
};
