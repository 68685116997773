import c from 'classnames';
import { XCircle } from 'phosphor-icons';

type FormErrorMessageProps = {
  inputId: string;
  errorMessage: string;
  className?: string;
};

export const FormErrorMessage = ({
  inputId,
  errorMessage,
  className,
}: FormErrorMessageProps) => {
  return (
    <div
      id={`${inputId}-error`}
      className={c(
        'tiny-text mt-3 flex items-center gap-1 text-grey-200',
        className
      )}
    >
      <XCircle weight="fill" className="text-red-500" size={16} />
      {errorMessage}
    </div>
  );
};
