import { ImageFocalPoint, Image as ImageType } from '../../lib/types';
import { FocalPointImage } from '../FocalPointImage/FocalPointImage';
import { Gradient } from '../Images/Effects/Gradient';

type HeroProps = {
  title: string;
  description?: string | null;
  image: ImageType;
  slim?: boolean;
  imageFocusArea?: ImageFocalPoint | null;
};

export const Hero = ({
  title,
  description,
  image,
  slim,
  imageFocusArea,
}: HeroProps) => {
  return (
    <div
      className={
        slim
          ? 'relative flex min-h-32 w-full items-center justify-center py-7 md:min-h-44'
          : 'relative flex h-80 w-full items-center justify-center md:h-112'
      }
    >
      <FocalPointImage
        focalPoint={imageFocusArea?.focalPoint}
        image={image.url}
        alt={image.alt || ''}
        width={image.width!}
        height={image.height!}
      />
      <div className="main-grid z-10 grid-flow-row justify-between p-4">
        <h1
          className={
            slim
              ? 'page-heading col-span-4 block text-balance text-center text-white md:col-span-8 md:col-start-3'
              : 'hero col-span-4 block text-balance text-center text-white md:col-span-8 md:col-start-3'
          }
        >
          {title}
        </h1>
        {description && (
          <span className="body col-span-4 mt-6 block text-balance text-center text-white md:col-span-6 md:col-start-4">
            {description}
          </span>
        )}
      </div>
      <Gradient className="absolute top-0 h-full w-full" />
    </div>
  );
};
