type FormRadioButtonsProps = {
  radioButtons?: { label: string; value: string }[];
  selectedOption?: string;
  setSelectedOption: (value: string) => void;
};

export const FormRadioButtons = ({
  radioButtons,
  selectedOption = '',
  setSelectedOption,
}: FormRadioButtonsProps) => {
  return (
    <>
      {radioButtons && (
        <div className="mb-6 flex flex-col gap-y-2">
          {radioButtons.map((radioButton) => (
            <div
              key={radioButton.value}
              className="flex cursor-pointer items-center"
            >
              <input
                type="radio"
                id={radioButton.value}
                name="abuse-report"
                value={radioButton.value}
                className="h-4 w-4 cursor-pointer"
                checked={selectedOption === radioButton.value}
                onChange={() => setSelectedOption(radioButton.value)}
                aria-labelledby={radioButton.value}
              />
              <label
                htmlFor={radioButton.value}
                className="cursor-pointer pl-4"
              >
                {radioButton.label}
              </label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
