'use client';

import c from 'classnames';
import xss from 'xss';
import { FormErrorMessage } from './FormErrorMessage';

type CheckboxProps = {
  label: string;
  id: string;
  name?: string;
  value?: string;
  required?: boolean;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: string;
};

export const Checkbox = ({
  label,
  id,
  name,
  value,
  isChecked,
  onChange,
  required = false,
  className = '',
  error,
}: CheckboxProps) => {
  /**
   * TODO: This can be turned on once this regression has been fixed: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/962
   */
  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className={className}>
      <div className="flex items-start">
        <input
          type="checkbox"
          className={c(
            "focus-outline mr-2 mt-1 h-6 w-6 flex-none appearance-none rounded-sm border border-grey-600 checked:border-blue checked:bg-blue checked:after:absolute checked:after:ml-[0.5rem] checked:after:mt-[0.23rem] checked:after:block checked:after:h-[0.75rem] checked:after:w-[0.45rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] lg:h-5 lg:w-5 lg:checked:after:ml-[0.4rem] lg:checked:after:mt-[0.18rem] lg:checked:after:h-[0.615rem] lg:checked:after:w-[0.41rem]",
            {
              ['border-red-500']: Boolean(error) && !isChecked,
            }
          )}
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onChange={onChange}
          required={required}
          aria-describedby={error ? `${id}-error` : undefined}
        />
        <label htmlFor={id} className="tiny-text text-white">
          <div
            className="[&_a]:tiny-text-link"
            dangerouslySetInnerHTML={{ __html: xss(label) }}
          />
        </label>
      </div>
      <div aria-live="polite">
        {error && !isChecked && (
          <FormErrorMessage inputId={id} errorMessage={error} />
        )}
      </div>
    </div>
  );
};
