'use client';
import { Icon } from '@phosphor-icons/react';
import * as icons from '.';

/**
 * @param name The name of the icon from the Phosphor library.
 * @returns A Phosphor-icon React-component.
 */
export const getIcon = (name: string): Icon => {
  return icons[name];
};
