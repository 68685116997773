import Image from 'next/image';
import { isValidImageUrl } from 'utils';
import { Price } from '../Price/Price';

type ProductCardSimpleProps = {
  publisher?: string;
  title: string;
  price: {
    currentPrice: string;
    originalPrice?: string;
    currency?: string;
    discountPercent?: number;
  };
  image: string;
};

export const ProductCardSimple = ({
  publisher,
  title,
  price,
  image,
}: ProductCardSimpleProps) => {
  const showOriginalPrice = price.originalPrice !== price.currentPrice;
  return (
    <div className="flex h-[85px] w-full flex-row rounded-sm bg-white transition duration-300 ease-in-out hover:shadow-lg">
      <div className="image-container w-[85px] flex-none bg-grey-500">
        {isValidImageUrl(image) && (
          <Image
            src={image}
            alt=""
            height={85}
            width={85}
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div
        className="flex h-full min-w-0 flex-col justify-center px-4"
        title={title}
      >
        <div>
          {publisher && (
            <div
              className="pb-1 text-xs uppercase text-grey-500"
              data-test="product-publisher"
            >
              {publisher}
            </div>
          )}
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap pb-2 text-sm"
            data-test="product-name"
          >
            {title}
          </div>
          <div>
            <div className="flex">
              {price.currentPrice && (
                <div
                  className="text-sm text-grey-600"
                  data-test="current-price"
                >
                  <Price value={price.currentPrice} currency={price.currency} />
                </div>
              )}
              {showOriginalPrice && price.originalPrice && (
                <div
                  className="text-sm text-grey-400 line-through"
                  data-test="original-price"
                >
                  <Price
                    value={price.originalPrice}
                    currency={price.currency}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
