export const alignmentStyles = {
  left: {
    imageStyle:
      'xl:h-auto xl:col-span-7 xl:col-start-6 xl:col-end-13 xl:order-last',
    text: 'xl:pr-5 col-span-4 xl:col-start-2 xl:col-end-6 order-first md:text-left',
    container: 'col-span-5',
    ctaClasses: 'lg:items-start',
    countdownContainer: 'xl:justify-start',
  },
  center: {
    imageStyle:
      'xl:absolute xl:col-span-12 xl:left-0 xl:top-0 xl:w-full xl:h-full',
    text: 'xl:flex xl:bg-black xl:w-[437px] text-center md:text-left xl:text-center',
    container: 'col-span-5 xl:px-4',
    ctaClasses: 'xl:justify-center',
    countdownContainer: 'xl:justify-center',
  },
  right: {
    imageStyle:
      'xl:h-auto xl:col-span-7 xl:col-start-1 xl:col-end-8 order-first',
    text: 'xl:pr-5 col-span-4 xl:col-start-8 xl:col-end-13 md:text-left order-last',
    container: 'col-span-4 xl:pl-8 xl:col-end-5',
    ctaClasses: 'xl:items-start',
    countdownContainer: 'xl:justify-start',
  },
};
