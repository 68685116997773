import c from 'classnames';
import Image from 'next/image';
import React from 'react';
import { ThirdPartyIframe } from '../ThirdPartyIframe/ThirdPartyIframe';
import { Video } from '../Video/Video';

export enum AssetMediaType {
  SCREENSHOT = 'SCREENSHOT',
  YOUTUBE = 'YOUTUBE',
  ATTACHMENT_VIDEO = 'ATTACHMENT_VIDEO',
  ATTACHMENT_AUDIO = 'ATTACHMENT_AUDIO',
  VIMEO = 'VIMEO',
  SOUNDCLOUD = 'SOUNDCLOUD',
  SKETCHFAB = 'SKETCHFAB',
  KEY_IMAGE = 'KEY_IMAGE',
  UNKNOWN = 'UNKNOWN',
}

export type GallerySlideData = {
  type: AssetMediaType;
  src: string;
  thumbnail?: string;
  thumbnailLabel?: string;
  alt?: string;
};

type GallerySlideProps = {
  slide: GallerySlideData;
  position?: 'details' | 'fullscreen-preview';
  /*
   * Hide all carousel items that are not currently the active item from screen readers.
   * */
  isHidden?: boolean;
  className?: string;
  style?: React.CSSProperties;
  hasCookieConsent: boolean;
  id?: string;
  videoCookieOverlayMicrocopy: {
    description: string;
    buttonLabel: string;
  };
};

const GallerySlide = ({
  slide,
  position = 'details',
  isHidden = false,
  className = '',
  style,
  id,
  hasCookieConsent,
  videoCookieOverlayMicrocopy,
}: GallerySlideProps) => {
  const sizes =
    position === 'details'
      ? '(max-width: 1024px) 100vw, (max-width: 1240px): 60vw, 710px'
      : '100vw';
  const isEmbedVideo =
    slide.type === AssetMediaType.YOUTUBE ||
    slide.type === AssetMediaType.VIMEO;

  return (
    <div
      id={id}
      data-test={id}
      style={style}
      className={c(
        'hide-inner-buttons-when-hidden relative aspect-video w-full shrink-0 grow-0 basis-full bg-grey-900 ',
        { ['invisible']: isHidden },
        className
      )}
      aria-hidden={isHidden}
    >
      {isEmbedVideo && (
        <Video
          cookieConsent={hasCookieConsent}
          title={slide.alt}
          embedUrl={slide.src}
          videoCookieOverlayMicrocopy={videoCookieOverlayMicrocopy}
          isVisible={!isHidden}
        />
      )}
      {slide.type === AssetMediaType.SCREENSHOT && (
        <>
          <Image
            // displays a blurred version of the image behind, if the image does not fit in the aspect ratio properly the blurred version is visible behind
            fill
            src={slide.src}
            alt=""
            sizes={sizes}
            className="pointer-events-none absolute top-0 scale-110 object-cover opacity-60 blur-md"
          />
          <Image
            fill
            src={slide.src}
            alt={slide.alt ?? ''}
            sizes={sizes}
            className="object-contain"
          />
        </>
      )}
      {slide.type !== AssetMediaType.SCREENSHOT && !isEmbedVideo && (
        <ThirdPartyIframe
          cookieConsent={hasCookieConsent}
          title={slide.alt}
          embedUrl={slide.src}
          cookieOverlayMicrocopy={videoCookieOverlayMicrocopy}
          isVisible={!isHidden}
        />
      )}
    </div>
  );
};

export default GallerySlide;
