import c from 'classnames';
type GradientProps = {
  className?: string;
};

export const Gradient = ({ className = '' }: GradientProps) => {
  return (
    <div
      className={c(
        'h-full w-full bg-gradient-to-t from-black to-transparent',
        className
      )}
    />
  );
};
