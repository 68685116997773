import Image from 'next/image';

type Props = {
  backgroundColor?: string;
  backgroundImage?: string;
  children: React.ReactNode;
};

export const Container = ({
  backgroundColor = '#000000',
  backgroundImage,
  children,
}: Props) => {
  return (
    <section
      className="relative w-full overflow-hidden py-12 lg:py-16"
      style={{ backgroundColor }}
    >
      {backgroundImage && (
        <Image
          fill
          alt=""
          src={backgroundImage}
          className="scale-110 object-cover opacity-50 blur-md"
        />
      )}
      <div className="relative z-10 last:*:mb-0">{children}</div>
    </section>
  );
};
