import { ButtonSize, ButtonStyle } from './types';
import { useButton } from './useButton';

export type CommonButtonProps = {
  style?: ButtonStyle;
  size?: ButtonSize;
  hasArrow?: boolean;
  label: string;
  isDarkMode?: boolean;

  /**
   * Optional classes
   */
  className?: string;
};

type ButtonProps = {
  /**
   * Optional button attributes
   */
  type?: JSX.IntrinsicElements['button']['type'];
  disabled?: boolean;

  /**
   * Optional click handler
   */
  onClick?: () => void;
} & CommonButtonProps;

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  type = 'button',
  style = 'primary',
  size = 'small',
  hasArrow = false,
  label,
  disabled = false,
  className = '',
  isDarkMode = false,
  onClick,
  ...props
}: ButtonProps) => {
  const { buttonClassNames, buttonContent } = useButton({
    hasArrow,
    style,
    size,
    label,
    className,
    isDarkMode,
  });

  return (
    <button
      type={type}
      className={buttonClassNames}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {buttonContent}
    </button>
  );
};
