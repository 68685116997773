'use client';
import c from 'classnames';
import { useState } from 'react';
import { Video as VideoType } from '../../lib/types';
import { Gradient } from '../Images/Effects/Gradient';
import { Video } from '../Video/Video';
import { VideoLibrary } from './VideoLibrary';

type LiveStreamType = {
  title: string;
  label: string;
  description: string;
  cookieConsent: boolean;
  videoCookieOverlay: {
    description: string;
    buttonLabel: string;
  };
  videos: VideoType[];
  videoLibraryLabel?: string;
  playAriaLabel: string;
  onVideoChange: (video: VideoType) => void;
};

export const LiveStream = ({
  title,
  label,
  description,
  cookieConsent = false,
  videoCookieOverlay,
  videos,
  videoLibraryLabel,
  playAriaLabel,
  onVideoChange,
}: LiveStreamType) => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);

  const handleVideoSelect = (video: VideoType) => {
    setCurrentVideo(video);
    onVideoChange(video);
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-10">
          <div className="mb-4 flex flex-col gap-4 md:flex-row md:items-center md:gap-6">
            <h2 className="header-mid order-2 md:order-1">{title}</h2>
            <div className="caption order-1 w-fit rounded-md bg-grey-200 px-3 py-1 md:order-2">
              {label}
            </div>
          </div>
          <div className="body-small mb-6">{description}</div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-3 md:items-stretch md:gap-4">
        <div
          className={c(`col-span-12`, {
            'md:col-span-10': videos.length > 1,
          })}
        >
          <div data-test="livestream-current-video">
            <div className="relative aspect-video overflow-hidden rounded-md bg-grey-200">
              <Video
                embedUrl={currentVideo.url}
                cookieConsent={cookieConsent}
                videoCookieOverlayMicrocopy={videoCookieOverlay}
                reactPlayerProps={{ playing: true, muted: true }}
              />
            </div>
          </div>
        </div>
        {videos.length > 1 && (
          <div className="relative col-span-12 md:col-span-2">
            <div className="mb-2 font-bold md:mb-6">{videoLibraryLabel}</div>
            <div className="hide-scrollbar relative md:h-[calc(100%-3rem)] md:overflow-y-scroll">
              <div className="w-full pt-0 md:absolute md:left-0 md:p-1">
                <div className="hide-scrollbar -ml-1 flex gap-4 overflow-x-scroll p-1 md:m-0 md:flex-col md:gap-0 md:overflow-x-visible md:p-0">
                  <VideoLibrary
                    videos={videos}
                    onVideoSelect={handleVideoSelect}
                    ariaLabel={playAriaLabel ?? 'Play'}
                  />
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute bottom-0 hidden h-14 w-full md:block">
              <Gradient className="from-white opacity-70" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
