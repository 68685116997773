import xss from 'xss';

type ReportModalSidebarProps = {
  sidebar: {
    title: string;
    description: string;
  }[];
};

export const ReportModalSidebar = ({ sidebar }: ReportModalSidebarProps) => {
  return (
    <div className="col-span-12 md:col-span-4">
      {sidebar.map((item) => (
        <div key={item.title} className="tiny-text mb-8 md:text-balance">
          <p className="mb-4 font-bold">{item.title}</p>
          <div
            className="text-grey-500 [&_a]:text-blue"
            dangerouslySetInnerHTML={{ __html: xss(item.description) }}
          />
        </div>
      ))}
    </div>
  );
};
