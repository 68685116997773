'use client';
import { useState } from 'react';
import { Button, Modal, RichText } from '../../.';
import { FormActions } from './Form/FormActions';
import {
  FormInputFeedback,
  MAX_CHARACTERS,
  MIN_CHARACTERS,
} from './Form/FormInputFeedback';
import { FormRadioButtons } from './Form/FormRadioButtons';
import { FormSubmissionFeedback } from './Form/FormSubmissionFeedback';
import { ReportModalSidebar } from './ReportModalSidebar';
import { AbuseReportProps, SubmissionState } from './types';

export const AbuseReport = ({
  title,
  sidebar,
  form,
  isActive,
  toggleModal,
  onSubmit,
  microcopy,
}: AbuseReportProps) => {
  const [text, setText] = useState('');
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );
  const [characterCount, setCharacterCount] = useState(0);
  const [submissionState, setSubmissionState] = useState<SubmissionState>({
    status: 'idle',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmissionState({ status: 'loading' });

    try {
      const result = await onSubmit({ text, selectedOption });
      setIsSubmitted(true);

      if (result === 'success') {
        setSubmissionState({ status: 'success' });
      } else {
        setSubmissionState({ status: 'error' });
      }
    } catch {
      setSubmissionState({ status: 'error' });
    }
  };

  const handleCloseFeedbackMessage = () => {
    if (submissionState.status === 'success') {
      toggleModal();
    } else if (submissionState.status === 'error') {
      setIsSubmitted(false);
    }
  };

  return (
    <Modal
      title={title}
      showTitle={!isSubmitted}
      closeLabel={microcopy.closeLabel}
      size={isSubmitted ? 'small' : 'large'}
      isActive={isActive}
      closeModal={toggleModal}
    >
      {!isSubmitted ? (
        <div className="grid grid-cols-12 gap-4 px-6 md:px-11 md:pt-4">
          <ReportModalSidebar sidebar={sidebar} />
          <div className="col-span-12 md:col-span-8">
            <h3 className="body-bold mb-4">{form.subtitle}</h3>
            <RichText html={form.description} className="mb-6 w-full" />
            <form onSubmit={handleFormSubmit}>
              <FormRadioButtons
                radioButtons={form.radioButtons}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              <label htmlFor="abuse-report-textarea" className="sr-only">
                {form.textareaPlaceholder}
              </label>
              <textarea
                id="abuse-report-textarea"
                className="min-h-48 w-full rounded-md border border-grey-400 px-6 py-5 placeholder:text-grey-600"
                placeholder={form.textareaPlaceholder}
                aria-label={form.textareaPlaceholder}
                onChange={(e) => {
                  const value = e.target.value;
                  setText(value);
                  setCharacterCount(value.length);
                }}
                value={text}
              ></textarea>
              <FormInputFeedback
                minCharactersMessage={microcopy.minCharacters}
                characterCountMessage={microcopy.characterCount}
                characterCount={characterCount}
              />
              <FormActions
                cancel={toggleModal}
                submit={() => onSubmit}
                isDisabled={
                  characterCount < MIN_CHARACTERS ||
                  characterCount > MAX_CHARACTERS ||
                  submissionState.status === 'loading'
                }
              />
            </form>
          </div>
        </div>
      ) : (
        <div className="mb-9 ml-11 mr-11 flex flex-col items-center justify-center gap-8">
          <FormSubmissionFeedback
            successMessage={microcopy.submissionSuccess}
            errorMessage={microcopy.submissionError}
            status={submissionState.status}
          />
          <Button
            label={microcopy.closeLabel}
            style="secondary"
            onClick={handleCloseFeedbackMessage}
          />
        </div>
      )}
    </Modal>
  );
};
