import c from 'classnames';
import Link from 'next/link';
import {
  DEFAULT_LOCALE,
  Locale,
  formatDistanceToNow,
  interpolateStringWithData,
} from 'utils';
import { Avatar } from '../Avatar/Avatar';
import { Stars } from '../Stars/Stars';

type ProductReviewProps = {
  review: {
    user: {
      id: string;
      name: string;
      profilePicture?: string;
    };
    assetVersion: string;
    content: {
      title: string;
      body: string;
    };
    rating: number;
    date: string;
    reviewDisclaimer?: string;
    helpfulVotes?: {
      count: number;
      total: number;
    };
  };
  truncate?: boolean;
  onTruncationClick?: () => void;
  microcopy: {
    assetVersion: string;
    ratingAriaLabel: string;
    truncatedViewFull: string;
  };
  locale: string;
};

export const ProductReview = ({
  review,
  onTruncationClick = () => {},
  microcopy,
  truncate = false,
  locale = DEFAULT_LOCALE,
}: ProductReviewProps) => {
  const { user, content } = review;
  return (
    <div data-test="review-item">
      <Link
        href={`/users/${user.id}`}
        className="focus-outline mb-3 inline-flex items-center"
        data-test="review-user"
      >
        <Avatar
          userProfilePicture={user.profilePicture}
          userName={user.name}
          size={24}
          className="mr-2 shrink-0"
        />
        <span className="tiny-text" data-test="review-user-name">
          {user.name}
        </span>
      </Link>
      <div className="tiny-text mb-4 flex gap-y-2 overflow-hidden text-grey-600 [&>*:not(:last-child)]:border-r [&>*]:mr-3 [&>*]:border-grey-200 [&>*]:pr-3">
        <span data-test="review-asset-version" className="whitespace-nowrap">
          {interpolateStringWithData(microcopy.assetVersion, {
            version: review.assetVersion,
          })}
        </span>
        {review.date && (
          <span
            data-test="review-asset-timestamp"
            className="whitespace-nowrap"
          >
            {formatDistanceToNow(review.date, locale as Locale)}
          </span>
        )}
        {review.reviewDisclaimer && (
          <span
            data-test="review-disclaimer"
            className="flex-shrink overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {review.reviewDisclaimer}
          </span>
        )}
      </div>
      <div className="mb-3" data-test="review-rating">
        <Stars
          value={review.rating}
          width={16}
          height={16}
          ariaLabel={interpolateStringWithData(microcopy.ratingAriaLabel, {
            rating: String(review.rating),
          })}
        />
      </div>
      <span className="review-content mb-5 block">
        <h3 className="body-small-bold mb-4" data-test="review-title">
          {content.title}
        </h3>
        <p
          className={c('body-small overflow-hidden whitespace-pre-line', {
            'line-clamp-3': truncate,
          })}
          data-test="review-body"
        >
          {content.body}
        </p>
      </span>
      {truncate && (
        <button
          onClick={onTruncationClick}
          className="focus-outline text-sm text-blue"
          data-test="review-toggle-truncate"
        >
          {microcopy.truncatedViewFull}
        </button>
      )}
    </div>
  );
};
