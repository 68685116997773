'use client';
import c from 'classnames';
import dynamic from 'next/dynamic';
import { Pause, Play } from 'phosphor-icons';
import { useEffect, useState } from 'react';
import { formatTitleForID } from 'utils';
import { Video } from '../../lib/types';

const ReactPlayer = dynamic(() => import('react-player/file'), {
  ssr: false,
});

export const BackgroundVideo = ({
  title,
  url,
  fallbackImage,
  description,
  objectFit = 'cover',
}: Video & { objectFit?: 'cover' | 'contain' }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const descriptionId = `${title ? formatTitleForID(title) : 'background-video'}-description`;

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !window.matchMedia('(prefers-reduced-motion: reduce)').matches
    ) {
      setIsPlaying(true);
    }
  }, []);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div
      className={c('[&_video]:md:absolute', {
        '[&_video]:object-cover': objectFit === 'cover',
        '[&_video]:object-contain': objectFit === 'contain',
      })}
    >
      <ReactPlayer
        title={title}
        poster={fallbackImage}
        playing={isPlaying}
        playsinline
        muted
        loop
        data-test="background-video"
        aria-describedby={descriptionId}
        url={url}
        width={'100%'}
        height={'100%'}
      />
      {description && (
        <p id={descriptionId} className="sr-only">
          {description}
        </p>
      )}
      <button
        className="focus-outline absolute bottom-5 right-5 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-grey-700 text-white text-opacity-50 opacity-0 transition-all hover:text-opacity-100 focus:opacity-100 group-focus-within:opacity-100 group-hover:opacity-100"
        onClick={togglePlay}
      >
        {isPlaying ? <Pause weight="fill" /> : <Play weight="fill" />}
      </button>
    </div>
  );
};
