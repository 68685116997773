import c from 'classnames';
import Image from 'next/image';
import { DEFAULT_LOCALE, formatDistanceToNowStrict, Locale } from 'utils';

export enum NotificationType {
  INFO = 'info',
  HELPFUL_HINT = 'helpful-hint',
  WISHLIST_PACKAGE_ON_SALE = 'wishlist-package-on-sale',
  SALE_STARTED = 'sale-started',
  REVIEW_REPLY = 'review-reply',
  REVIEW_REMINDER = 'review-reminder',
  PACKAGE_UPDATED = 'package-updated',
  REFUND_APPROVED = 'refund-approved',
  SUMMER_SALE = 'summer-sale',
  SALE_ENDS_TODAY = 'sale-ends-today',
}

const blueColor = {
  text: 'text-blue',
  fill: 'fill-blue',
};

const redColor = {
  text: 'text-red-500',
  fill: 'fill-red-500',
};

const greenColor = {
  text: 'text-green-500',
  fill: 'fill-green-500',
};

const NOTIFICATION_TYPE_TO_COLOR: Record<
  NotificationType,
  { text: string; fill: string }
> = {
  [NotificationType.INFO]: blueColor,
  [NotificationType.HELPFUL_HINT]: blueColor,
  [NotificationType.WISHLIST_PACKAGE_ON_SALE]: redColor,
  [NotificationType.SALE_STARTED]: redColor,
  [NotificationType.REVIEW_REPLY]: blueColor,
  [NotificationType.REVIEW_REMINDER]: blueColor,
  [NotificationType.PACKAGE_UPDATED]: greenColor,
  [NotificationType.REFUND_APPROVED]: redColor,
  [NotificationType.SUMMER_SALE]: redColor,
  [NotificationType.SALE_ENDS_TODAY]: redColor,
};

export type NotificationProps = {
  id: string;
  type: NotificationType;
  link?: string;
  title: string;
  text: string;
  imageUrl: string;
  datePublishedTimestamp: number;
  isRead: boolean;
  markAsRead: (id: string) => void;
  currentLocale?: Locale;
  className?: string;
};

export const Notification = ({
  id,
  type,
  link,
  title,
  text,
  imageUrl,
  datePublishedTimestamp,
  isRead,
  className = '',
  currentLocale = DEFAULT_LOCALE,
  markAsRead,
}: NotificationProps) => {
  const typeColor = NOTIFICATION_TYPE_TO_COLOR[type] ?? blueColor;
  const relativeTime = formatDistanceToNowStrict(
    datePublishedTimestamp,
    currentLocale
  );

  function handleMarkAsRead() {
    markAsRead(id);
  }

  const ContainerElement = link ? 'a' : 'div';
  const containerElementSpecificProps = link
    ? { href: link, target: '_blank' }
    : {};

  return (
    <ContainerElement
      {...containerElementSpecificProps}
      data-test="notification-list-item"
      className={c(
        'flex w-full items-center gap-3 py-4 pl-3 pr-10',
        {
          'bg-blue/10': !isRead,
          'cursor-pointer hover:bg-blue/20': link,
        },
        className
      )}
      onClick={handleMarkAsRead}
    >
      <div
        className={c('h-2 w-2 flex-shrink-0 rounded-full', {
          'bg-red-500': !isRead,
        })}
      />
      <Image
        width={60}
        height={60}
        src={imageUrl}
        alt=""
        className="flex-shrink-0 rounded-full"
      />
      <div>
        <div className={c('flex gap-1 pb-1 text-xs uppercase', typeColor.text)}>
          {title}
        </div>
        <p className="pb-1 text-sm">{text}</p>
        <p className="text-sm text-grey-600">{relativeTime}</p>
      </div>
    </ContainerElement>
  );
};
