'use client';
import {
  Middleware,
  arrow as arrowMiddleware,
  offset as offsetMiddleware,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { useRef, useState } from 'react';

export const useTooltip = ({
  offset = 0,
  arrow = false,
}: {
  offset?: number;
  arrow?: boolean;
}) => {
  /**
   * Required state & refs
   */
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Determine what middleware to implement based on hook arguments
   */
  const middleware = [] as Middleware[];
  if (offset > 0) {
    middleware.push(offsetMiddleware(offset));
  }
  if (arrow) {
    middleware.push(arrowMiddleware({ element: arrowRef }));
  }

  /**
   * Stand up necessary objects & configuration for tooltip-interactivity & positioning
   */
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware,
  });

  const hover = useHover(context);
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
  ]);

  return {
    context,
    isOpen,
    setIsOpen,
    refs,
    floatingStyles: {
      ...floatingStyles,
      zIndex: 40,
    },
    getReferenceProps,
    getFloatingProps,
    arrowRef,
  };
};
