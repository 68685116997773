export const roundToMaxOneDecimalPlace = (number: number) => {
  const roundedNumber = Math.round(number * 10) / 10;
  return roundedNumber;
};

export const roundToNearestHalfStar = (number: number) => {
  const decimalPart = ((number * 10) % 10) / 10;
  // Round decimal to avoid js floating point errors
  const roundedDecimal = Math.round(decimalPart * 100) / 100;
  const integerPart = Math.round(number - decimalPart);

  return Math.round((integerPart + roundedDecimal) * 2) / 2;
};
