import c from 'classnames';
import Image from 'next/image';
import { toAvatarInitials } from 'utils';

type AvatarProps = {
  userProfilePicture?: string | null;
  userName: string;
  size?: number;
  className?: string;
};

const DEFAULT_SIZE = 72;
const HALVED = 2;

export const Avatar = ({
  userProfilePicture,
  userName,
  size = DEFAULT_SIZE,
  className = '',
}: AvatarProps) => {
  const userNameInitials = toAvatarInitials(userName);

  return (
    <div
      data-test="logged-in-user-avatar"
      className={c(
        `flex items-center justify-center overflow-hidden rounded-full bg-blue`,
        className
      )}
      style={{ width: size, height: size }}
    >
      {userProfilePicture ? (
        <Image
          width={size}
          height={size}
          src={userProfilePicture}
          alt={userName}
        />
      ) : (
        <div
          className="text-center text-white"
          style={{
            fontSize: size / HALVED,
          }}
        >
          {userNameInitials}
        </div>
      )}
    </div>
  );
};
