import Image from 'next/image';

type FocalPointImageProps = {
  focalPoint?: { x: number; y: number };
  image: string;
  width: number;
  height: number;
  alt: string;
  testId?: string;
};

const DEFAULT_OFFSET_CENTER = 50;

export const FocalPointImage = ({
  focalPoint,
  height,
  image,
  width,
  alt,
  testId,
}: FocalPointImageProps) => {
  const getOffset = (axis: 'x' | 'y') => {
    const dimension = axis === 'x' ? width : height;
    /**
     * In case the focal point is not set or we don't know the images width/height
     * we default to 50% x and y offset to center image
     */
    if (typeof focalPoint?.[axis] !== 'number' || !dimension) {
      return DEFAULT_OFFSET_CENTER;
    }

    // Return offset as percentage number
    return (focalPoint?.[axis] / dimension) * 100;
  };

  return (
    <div className="absolute flex h-full w-full items-center justify-center">
      <Image
        data-test={testId}
        src={image}
        alt={alt}
        fill
        className="object-cover"
        style={{
          objectPosition: `${getOffset('x')}% ${getOffset('y')}%`,
        }}
      />
    </div>
  );
};
