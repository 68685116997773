import c from 'classnames';
import { interpolateStringWithData } from 'utils';

type FormInputFeedbackProps = {
  characterCount: number;
  minCharactersMessage: string;
  characterCountMessage: string;
};

export const MIN_CHARACTERS = 50;
export const MAX_CHARACTERS = 500;

export const FormInputFeedback = ({
  characterCount,
  minCharactersMessage,
  characterCountMessage,
}: FormInputFeedbackProps) => {
  return (
    <div className="text-sm">
      {characterCount < MIN_CHARACTERS && (
        <span className="text-grey-600">{minCharactersMessage}</span>
      )}
      {characterCount >= MIN_CHARACTERS && (
        <span
          className={c(
            characterCount > MAX_CHARACTERS ? 'text-red-500' : 'text-grey-600'
          )}
        >
          {interpolateStringWithData(characterCountMessage, {
            count: characterCount.toString(),
            max: MAX_CHARACTERS.toString(),
          })}
        </span>
      )}
    </div>
  );
};
