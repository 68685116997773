'use client';
import c from 'classnames';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import { useAnimateVisibility } from '../../lib/hooks/useAnimateVisibility';
import { IconButton } from '../Button/IconButton';

type ModalProps = {
  title: string;
  showTitle?: boolean;
  size?: 'small' | 'medium' | 'large';
  closeLabel: string;
  isActive?: boolean;
  portalDomNode?: HTMLElement | null;
  closeModal: () => void;
  actions?: ReactNode;
  children: ReactNode;
};

export const Modal = ({
  title,
  showTitle = true,
  closeLabel,
  closeModal,
  portalDomNode,
  children,
  size = 'small',
  isActive = false,
  actions = null,
}: ModalProps) => {
  const titleId = `modal-title-${title.replaceAll(' ', '-')}`;
  // used in-conjunction with isActive to animate modal mounting + unmounting
  const { isAnimatedIn, isAnimatedOut, isAnimating } =
    useAnimateVisibility(isActive);

  const modalWrapperClassNames = c(
    'w-full h-full fixed z-modal top-0 left-0 flex justify-center items-center bg-black/80 transition duration-300',
    {
      'opacity-1': isAnimatedIn,
      'opacity-0': isAnimating,
    }
  );
  const modalClassNames = c(
    'bg-white rounded-3xl max-w-[calc(100vw-2rem)] overflow-hidden transition duration-300',
    {
      'w-[31rem]': size === 'small',
      'w-[43rem]': size === 'medium',
      'w-[69rem]': size === 'large',
      'opacity-1 scale-1': isAnimatedIn,
      'opacity-0 scale-75 motion-reduce:transform-none': isAnimating,
    }
  );

  // stop rendering modal when both parent says modal is no longer active, and animation is complete
  if (isAnimatedOut) {
    return null;
  }

  return createPortal(
    <div className={modalWrapperClassNames}>
      <FocusOn returnFocus onEscapeKey={closeModal} onClickOutside={closeModal}>
        <div
          data-test={titleId}
          role="dialog"
          aria-modal="true"
          aria-labelledby={titleId}
          className={modalClassNames}
        >
          <div className="mx-4 my-7 flex justify-start lg:m-7">
            <IconButton
              data-test="modal-close-button"
              iconName="X"
              ariaLabel={closeLabel}
              onClick={closeModal}
            />
          </div>
          <h2
            id={titleId}
            className={c('mx-6 mb-4 text-2xl font-bold md:mx-11', {
              'sr-only': !showTitle,
            })}
          >
            {title}
          </h2>
          <div
            data-test="modal-scroll-container"
            className="scrollbar-gutter-stable max-h-[calc(100vh-10rem)] overflow-auto"
          >
            {children}
          </div>
          {actions && <div className="w-full px-11 py-9">{actions}</div>}
        </div>
      </FocusOn>
    </div>,
    portalDomNode ?? document.body
  );
};
