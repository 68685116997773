import c from 'classnames';
import { ImageFocalPoint, Image as ImageType } from '../../lib/types';
import { CTA } from '../Button/types';
import { FocalPointImage } from '../FocalPointImage/FocalPointImage';
import { Gradient } from '../Images/Effects/Gradient';
import { LinkButton } from '../Link/LinkButton';

type CalloutProps = {
  title: string;
  description?: string | null;
  image: ImageType;
  imageFocusArea?: ImageFocalPoint | null;
  align?: 'left' | 'right';
  actions: CTA[];
  onCtaClick: (ctaLabel: string) => void;
};

export const Callout = ({
  title,
  description,
  image,
  imageFocusArea,
  align = 'left',
  actions,
  onCtaClick,
}: CalloutProps) => {
  return (
    <div className="main-grid">
      <div className="relative col-span-4 grid grid-cols-12 items-center gap-x-4 overflow-hidden rounded-xl px-4 py-12 md:col-span-12 md:px-0 lg:py-16">
        <FocalPointImage
          image={image.url}
          alt={image.alt || ''}
          width={image.width!}
          height={image.height!}
          focalPoint={imageFocusArea?.focalPoint}
        />
        <div
          className={c('z-10 col-span-12 md:col-span-5', {
            'md:col-start-2': align === 'left',
            'md:col-start-7': align === 'right',
          })}
        >
          <h2 className="hero block text-balance text-white">{title}</h2>
          {description && (
            <span className="body mt-5 block text-white">{description}</span>
          )}
          <div className="mt-8 flex flex-col items-start gap-8 lg:flex-row">
            {actions.map((action) => (
              <LinkButton
                href={action.url}
                openLinkInNewTab={action.openInNewTab}
                label={action.label}
                size="medium"
                hasArrow={action.style === 'primary'}
                style={action.style}
                className="text-white"
                key={action.label}
                onClick={() => onCtaClick(action.label)}
              />
            ))}
          </div>
        </div>
        <Gradient className="absolute top-0 h-full w-full" />
      </div>
    </div>
  );
};
