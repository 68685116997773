import { FetchError } from '@lib/types/api/FetchError';
import { isTest } from '../env';

const isRelativeUrl = (url: string) => url.startsWith('/');

/**
 * @throws {FetchError}
 */
export const failingFetcher = async (url, options: RequestInit) => {
  // append base url when running in test since Mock Service Worker doesn't resolve relative urls in Node environments
  const BASE_URL =
    isTest() && isRelativeUrl(url) ? 'http://localhost:3000' : '';

  const response = await fetch(BASE_URL + url, options);
  if (!response.ok) {
    throw new FetchError(
      `${url}: ${response.status} ${response.statusText}`,
      response.status
    );
  }

  try {
    const body = await response.json();
    if (body) {
      return body;
    }
  } catch (error) {
    // if there is no body to parse from backend .json() can throw JSON error
    // response still succeeded so simply return empty body
    return {};
  }

  return {};
};
