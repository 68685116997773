'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Video as VideoType } from '../../lib/types';

export const HomepageHeroEmbed = ({
  video,
  hasCookieConsent,
}: {
  video: VideoType;
  hasCookieConsent: boolean;
}) => {
  const [host, setHost] = useState<string | null>(null);
  let url = video.url;

  useEffect(() => {
    setHost(window.location.host);
  }, []);

  if (!hasCookieConsent) {
    return (
      <Image fill src={video.fallbackImage ?? ''} alt={video.title ?? ''} />
    );
  }

  // add location host to twitch embed url
  if (video.url.includes('twitch')) {
    url = `${url}&parent=${host}`;
  }

  return (
    <iframe
      width="100%"
      height="100%"
      src={url}
      title={video.title}
      allowFullScreen
      allow="autoplay"
      loading="lazy"
    ></iframe>
  );
};
