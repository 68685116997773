/**
 * Hook used to determine if text in element is truncated or not
 * Useful for determining if a Tooltip should be displayed on hover or not
 * We use scrollHeight and clientHeight because of line-clamp CSS property on the element
 *
 * @param elementRef ref of the element that has the possibility for truncated text
 */
export const useIsTextTruncated = <T extends HTMLElement>(
  elementRef: React.RefObject<T>
): boolean => {
  let isTextTruncated = false;
  if (elementRef.current) {
    const { scrollHeight, clientHeight } = elementRef.current;
    isTextTruncated = clientHeight < scrollHeight;
  }

  return isTextTruncated;
};
