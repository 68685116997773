import { Button } from '../Button/Button';

type CookieConsentOverlayType = {
  description: string;
  buttonLabel: string;
};

export const CookieConsentOverlay = ({
  description = 'This content is hosted by a third party provider that does not allow video views without acceptance of Targeting Cookies. Please set your cookie preferences for Targeting Cookies to yes if you wish to view videos from these providers.',
  buttonLabel = 'Open Cookie Settings',
}: CookieConsentOverlayType) => {
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center bg-black p-4"
      data-test="cookie-consent-required-notice"
    >
      <div className="mb-6 w-full text-center text-xs text-white xl:w-2/3 xl:text-sm">
        {description}
      </div>
      <Button
        label={buttonLabel ?? ''}
        isDarkMode={true}
        style="secondary"
        className="ot-sdk-show-settings text-xs tracking-[0.08rem] lg:text-sm lg:tracking-wide"
      />
    </div>
  );
};
