import { Star, StarHalf } from 'phosphor-icons';
import { ReactElement } from 'react';
import { roundToNearestHalfStar } from 'utils';

type StarsProps = {
  value: number;
  max?: number;
  width?: number;
  height?: number;
  ariaLabel?: string;
};

const MAX_DEFAULT = 5;
const WIDTH_DEFAULT = 25;
const HEIGHT_DEFAULT = 25;

export const Stars = ({
  value,
  ariaLabel = `${value} out of 5 stars`,
  max = MAX_DEFAULT,
  width = WIDTH_DEFAULT,
  height = HEIGHT_DEFAULT,
}: StarsProps) => {
  const stars: ReactElement[] = [];
  let remainingScore = roundToNearestHalfStar(value);
  const sharedProps = {
    width,
    height,
    className: 'fill-current',
  };

  for (let i = 0; i < max; i++) {
    if (remainingScore >= 1) {
      stars.push(
        <Star {...sharedProps} key={i} data-test="star-full" weight="fill" />
      );
      remainingScore -= 1;
      continue;
    }

    if (remainingScore === 0.5) {
      stars.push(
        <StarHalf
          {...sharedProps}
          key={i}
          data-test="star-half"
          weight="fill"
        />
      );
      remainingScore -= 0.5;
      continue;
    }

    stars.push(
      <Star {...sharedProps} key={i} data-test="star-empty" aria-hidden />
    );
  }

  return (
    <div
      role="img"
      aria-label={ariaLabel}
      className="flex gap-0.5"
      data-test="stars-rating"
      data-test-score={value}
    >
      {stars}
    </div>
  );
};
