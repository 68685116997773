type CountdownTileProps = {
  value: number;
  unit: string;
  label?: string;
};

const DEFAULT_UNIT_DISPLAY_VALUES = {
  days: 'days',
  hours: 'hrs',
  minutes: 'min',
  seconds: 'sec',
};

export const CountdownTile = ({ value, unit, label }: CountdownTileProps) => {
  const nonNegativeValue = value >= 0 ? value : 0;
  /**
   * Per the design specs we want to always have 2 numbers,
   * even if the value is single-digit or 0.
   */
  const paddedValue = String(nonNegativeValue).padStart(2, '0');
  const defaultUnitLabel = DEFAULT_UNIT_DISPLAY_VALUES[unit];

  return (
    <div
      className="flex w-16 flex-col items-center justify-center rounded-[4px] border-2 border-blue bg-black text-white xl:w-20"
      data-test="tile-wrapper"
      suppressHydrationWarning
    >
      <span
        className="mb-0.5 text-3xl font-bold lg:mb-1 lg:text-5xl"
        data-test="tile-value"
        suppressHydrationWarning
      >
        {paddedValue}
      </span>
      <span
        className="-mr-0.5 text-xs font-semibold uppercase tracking-wide lg:-mr-1 lg:text-sm"
        data-test="tile-unit"
      >
        {label || defaultUnitLabel}
      </span>
    </div>
  );
};
