type PageTitleProps = {
  title: string;
  description?: string | null;
};

export const PageTitle = ({ title, description }: PageTitleProps) => {
  return (
    <div className="main-grid mb-20 mt-14 lg:mb-32 lg:mt-20">
      <h1 className="page-heading col-span-4 text-balance md:col-span-10">
        {title}
      </h1>
      {description && (
        <p className="body col-span-4 mt-5 text-balance md:col-span-6">
          {description}
        </p>
      )}
    </div>
  );
};
