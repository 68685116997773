'use client';

import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { useIsClient } from '../../lib/hooks/useIsClient';
import { CookieConsentOverlay } from '../CookieConsentOverlay/CookieConsentOverlay';

type VideoProps = {
  title?: string;
  embedUrl: string;
  cookieConsent: boolean;
  videoCookieOverlayMicrocopy?: {
    description: string;
    buttonLabel: string;
  };
  reactPlayerProps?: ReactPlayerProps;
  isVisible?: boolean;
};

export const Video = ({
  title,
  embedUrl,
  cookieConsent = false,
  videoCookieOverlayMicrocopy = {
    description:
      'This content is hosted by a third party provider that does not allow video views without acceptance of Targeting Cookies. Please set your cookie preferences for Targeting Cookies to yes if you wish to view videos from these providers.',
    buttonLabel: 'Open Cookie Settings',
  },
  reactPlayerProps,
  isVisible = true,
}: VideoProps) => {
  const isClient = useIsClient();

  if (!isClient) {
    return <div className="h-full w-full bg-grey-200" />;
  }

  return (
    <div className="h-full w-full">
      {!cookieConsent && (
        <CookieConsentOverlay {...videoCookieOverlayMicrocopy} />
      )}
      {/*
       * use isVisible so we can instruct the component to only render the third party element if it should actually be rendered on the screen. (ie: GallerySlide, only render video when slide is actually selected)
       * This is a bit of a hack so that we can have the CookieConsent button available in the DOM when the One Trust script initializes,
       * and so that we don't load extra third party code unless its actually needed */}
      {cookieConsent && isVisible && (
        <ReactPlayer
          url={embedUrl}
          width="100%"
          height="100%"
          title={title}
          controls
          allowFullScreen
          {...reactPlayerProps}
        />
      )}
    </div>
  );
};
