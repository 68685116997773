'use client';
import { CommonButtonProps } from '../Button/Button';
import { useButton } from '../Button/useButton';
import { Link } from './Link';

type LinkProps = {
  label: string;
  href: string | null;
  openLinkInNewTab?: boolean;
  isDarkMode?: boolean;
  onClick?: () => void;
} & CommonButtonProps;

/**
 * Renders a next/link if internal link, anchor tag if external link
 */
export const LinkButton = ({
  href,
  openLinkInNewTab = true,
  style = 'link',
  hasArrow = false,
  size = 'small',
  className = '',
  isDarkMode = false,
  label,
  onClick = () => {},
  ...props
}: LinkProps) => {
  const { buttonClassNames, buttonContent } = useButton({
    hasArrow,
    style,
    size,
    label,
    className,
    isDarkMode,
  });

  if (!href) {
    return null;
  }

  return (
    <Link
      href={href}
      target={openLinkInNewTab ? '_blank' : undefined}
      className={buttonClassNames}
      onClick={onClick}
      {...props}
    >
      {buttonContent}
    </Link>
  );
};
