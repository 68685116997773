import { SubmissionState } from '../types';

type FormSubmissionFeedbackProps = {
  status: SubmissionState['status'];
  successMessage: string;
  errorMessage: string;
};

export const FormSubmissionFeedback = ({
  status,
  successMessage,
  errorMessage,
}: FormSubmissionFeedbackProps) => {
  return (
    <>
      {status === 'success' && (
        <div className="body-bold mt-4">{successMessage}</div>
      )}
      {status === 'error' && (
        <div className="body mt-4 text-red-500">{errorMessage}</div>
      )}
    </>
  );
};
