'use client';
import { IconProps } from '@phosphor-icons/react';
import { getIcon } from './getIcon';

type DynamicIconProps = IconProps & {
  iconName: string;
};

/**
 * Wrapper component for any dynamic icon string passed in, so that if the icon name is not found in our Icon components no errors are thrown
 *
 * @param iconName name that should match an Icon component
 */
export const DynamicIcon = ({ iconName, ...props }: DynamicIconProps) => {
  const IconComponent = getIcon(iconName);

  return IconComponent ? <IconComponent {...props} /> : null;
};
