import { DynamicIcon } from 'phosphor-icons/react';
import { interpolateStringWithData } from 'utils';

const ACTION_VOTE_ICON_SIZE = 20;

type ProductReviewActionsProps = {
  actions: {
    onReportReview: () => void;
    onUpVoteReview: () => void;
    onDownVoteReview: () => void;
  };
  microcopy: {
    otherFoundHelpful: string;
    othersFoundHelpful: string;
    voteReviewPrompt?: string;
    reportReviewPrompt?: string;
    upVoteReviewAriaLabel?: string;
    downVoteReviewAriaLabel?: string;
  };
  helpfulVotes?: {
    count: number;
    total: number;
  };
};

export const ProductReviewActions = ({
  actions,
  microcopy,
  helpfulVotes,
}: ProductReviewActionsProps) => {
  const pluralizedFoundHelpfulMicrocopy =
    helpfulVotes?.total === 1
      ? microcopy.otherFoundHelpful
      : microcopy.othersFoundHelpful;

  return (
    <div className="actions tiny-text">
      {helpfulVotes && helpfulVotes.total > 0 && (
        <span
          className="mb-5 block text-grey-900 md:mb-3"
          data-test="review-actions-other-users"
        >
          {interpolateStringWithData(pluralizedFoundHelpfulMicrocopy, {
            count: String(helpfulVotes?.count),
            total: String(helpfulVotes?.total),
          })}
        </span>
      )}
      <div
        className="flex flex-col items-start md:flex-row md:items-baseline"
        data-test="review-actions"
      >
        <div className="mb-3 flex flex-col">
          <span className="mb-3 block border-grey-200 text-grey-600 md:mr-3 md:border-r md:pr-3">
            {microcopy.voteReviewPrompt}
          </span>
          <div>
            <button
              className="focus-outline mr-4"
              onClick={actions.onUpVoteReview}
              aria-label={microcopy.upVoteReviewAriaLabel}
              data-test="review-action-upvote"
            >
              <DynamicIcon iconName="ThumbsUp" size={ACTION_VOTE_ICON_SIZE} />
            </button>
            <button
              className="focus-outline"
              onClick={actions.onDownVoteReview}
              aria-label={microcopy.downVoteReviewAriaLabel}
              data-test="review-action-downvote"
            >
              <DynamicIcon iconName="ThumbsDown" size={ACTION_VOTE_ICON_SIZE} />
            </button>
          </div>
        </div>
        <button
          className="focus-outline text-grey-900"
          onClick={actions.onReportReview}
          data-test="review-action-report"
        >
          {microcopy.reportReviewPrompt}
        </button>
      </div>
    </div>
  );
};
