export const waitForElementToExist = (selector: string) => {
  return new Promise((resolve) => {
    const queryResult = document.querySelector(selector);
    if (queryResult) {
      resolve(queryResult);
    }

    const observer = new MutationObserver(() => {
      const mutationQueryResult = document.querySelector(selector);
      if (mutationQueryResult) {
        observer.disconnect();
        resolve(mutationQueryResult);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

const elementIsOutOfVerticalBounds = (
  element: HTMLElement,
  containerElement: HTMLElement
) =>
  element.offsetTop < containerElement.scrollTop ||
  element.offsetTop + element.offsetHeight >
    containerElement.scrollTop + containerElement.offsetHeight;

const elementIsOutOfHorizontalBounds = (
  element: HTMLElement,
  containerElement: HTMLElement
) =>
  element.offsetLeft < containerElement.scrollLeft ||
  element.offsetLeft + element.offsetWidth >
    containerElement.scrollLeft + containerElement.offsetWidth;

/**
 * Check if an element is visible on the screen within a scrollable container
 */
export const elementIsNotVisible = (
  element: HTMLElement,
  containerElement: HTMLElement
) =>
  elementIsOutOfVerticalBounds(element, containerElement) ||
  elementIsOutOfHorizontalBounds(element, containerElement);
