import { add, differenceInDays, type Duration } from 'date-fns';
import { format } from 'date-fns/format';
import { formatDistanceToNow as formatDistanceToNowDateFns } from 'date-fns/formatDistanceToNow';
import { formatDistanceToNowStrict as formatDistanceToNowStrictDateFns } from 'date-fns/formatDistanceToNowStrict';
import { formatDuration as formatDurationDateFns } from 'date-fns/formatDuration';
import { enUS, ja, ko, zhCN } from 'date-fns/locale';

import { Locale } from './locale';

const dateFnsLocales = {
  [Locale.ENGLISH]: enUS,
  [Locale.JAPANESE]: ja,
  [Locale.KOREAN]: ko,
  [Locale.CHINESE]: zhCN,
};

export function formatDistanceToNowStrict(
  datePublishedTimestamp: number,
  locale: Locale = Locale.ENGLISH
) {
  return formatDistanceToNowStrictDateFns(datePublishedTimestamp, {
    locale: dateFnsLocales[locale],
    addSuffix: true,
  });
}

export function formatDistanceToNow(
  date: string,
  locale: Locale = Locale.ENGLISH
) {
  return formatDistanceToNowDateFns(date, {
    locale: dateFnsLocales[locale],
    addSuffix: true,
  });
}

export function formatDate(
  date: Date,
  formatString: string,
  locale: Locale | string = Locale.ENGLISH
) {
  return format(date, formatString, {
    locale: dateFnsLocales[locale],
  });
}

export function formatDurationAsDays(
  duration: Duration,
  locale?: Locale | string
) {
  const today = new Date();
  const target = add(today, duration);
  const daysUntilTarget = differenceInDays(target, today);

  return formatDurationDateFns(
    { days: daysUntilTarget },
    {
      format: ['days'],
      locale: dateFnsLocales[locale ?? Locale.ENGLISH],
    }
  );
}

/**
 * Pass in the difference of client and server time in milliseconds to get the adjusted client date time.
 */
export function getAdjustedClientTime(
  date: Date,
  clientServerTimeDifference: number
) {
  return new Date(date.getTime() - clientServerTimeDifference);
}
