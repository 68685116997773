import { AnchorHTMLAttributes } from 'react';

/**
 * Note: As of https://jira.unity3d.com/browse/CPS-2273 this no longer conditionally renders a Next/link
 * based on the href pointing to an external URL.
 * See https://unity.slack.com/archives/C05S0BVMXA4/p1718731847153379?thread_ts=1718731666.299549&cid=C05S0BVMXA4
 * for the reasoning behind this change.
 */
export const Link = ({
  href,
  children,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  if (!href) {
    return null;
  }

  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
};
