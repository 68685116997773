'use client';
import { ChangeEvent, useEffect, useState } from 'react';
import { SelectArrow } from './SelectArrow';

type SelectProps = {
  id: string;
  options: { value: string; label: string }[];
  selectedOption?: string;
  ariaLabel?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

export const Select = ({
  id,
  selectedOption,
  onChange,
  options,
  ariaLabel = '',
}: SelectProps) => {
  const [value, setValue] = useState(selectedOption);

  useEffect(() => {
    setValue(selectedOption);
  }, [selectedOption]);

  const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <div className="relative flex items-center rounded border border-grey-600 text-white ">
      <fieldset>
        {/* legend visible for screen readers only */}
        <legend className="sr-only">{ariaLabel}</legend>
        <select
          id={id}
          className="h-10 w-full appearance-none bg-transparent pl-2 pr-10 text-white"
          onChange={handleOnChange}
          required
          aria-label={ariaLabel}
          value={value}
        >
          {options.map((option) => {
            return (
              <option
                className="text-black"
                key={option.value}
                value={option.value}
                data-test="select-option"
                data-test-value={option.value}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      </fieldset>
      <SelectArrow
        stroke="currentColor"
        className="pointer-events-none absolute right-2.5"
      />
    </div>
  );
};
