'use client';

import c from 'classnames';

import { CaretDown, CaretUp } from 'phosphor-icons';
import { useState } from 'react';
import {
  SubCategorySelectorItem,
  SubCategorySelectorItemProps,
} from './SubCategorySelectorItem';
import { ToggleMoreItemsButton } from './ToggleMoreItemsButton';

type SubCategorySelectorProps = {
  items: SubCategorySelectorItemProps[];
  maxInitialDisplay?: number;
  showMoreItemsLabel: string;
  showLessItemsLabel: string;
};

const MAX_INITIAL_DISPLAY_DEFAULT = 6;

export const SubCategorySelector = ({
  items,
  maxInitialDisplay = MAX_INITIAL_DISPLAY_DEFAULT,
  showLessItemsLabel,
  showMoreItemsLabel,
}: SubCategorySelectorProps) => {
  const hasMoreItemsThanDisplayMax = items.length > maxInitialDisplay;
  const [showMoreItems, setShowMoreItems] = useState(false);
  const excessItems = hasMoreItemsThanDisplayMax
    ? items.length - maxInitialDisplay
    : 0;

  const handleToggleShowMoreItems = () => {
    setShowMoreItems(!showMoreItems);
  };

  return (
    <div>
      <ul
        className="grid grid-cols-2 gap-5 md:grid-cols-6"
        data-test="sub-category-selector-container"
      >
        {items
          .filter((_, index) =>
            showMoreItems ? items.length : index < maxInitialDisplay
          )
          .map((item) => (
            <li key={`${item.label}-${item.url}`}>
              <SubCategorySelectorItem
                image={item.image}
                label={item.label}
                url={item.url}
              />
            </li>
          ))}
      </ul>
      <div
        className={c('mt-6 flex justify-center', {
          hidden: !hasMoreItemsThanDisplayMax,
        })}
        data-test="sub-category-toggle-more"
      >
        <ToggleMoreItemsButton
          excessItems={excessItems}
          onClick={handleToggleShowMoreItems}
          label={showMoreItems ? showLessItemsLabel : showMoreItemsLabel}
          icon={showMoreItems ? CaretUp : CaretDown}
        />
      </div>
    </div>
  );
};
