import c from 'classnames';
import { DynamicIcon } from 'phosphor-icons/react';

export type LabelAppearance = 'editorial' | 'highlight' | 'item';

type Props = {
  appearance: LabelAppearance;
  isDarkTheme?: boolean;
  iconName?: string;
  text: string;
  className?: string;
  testId?: string;
};

const styleClasses = {
  base: 'caption flex items-center',
  editorial: 'text-grey-500 text-xs',
  highlight:
    'py-2 px-4 rounded-lg bg-grey-700 text-grey-100 dark:text-grey-900 dark:bg-grey-200 text-sm',
  item: 'py-2 px-4 rounded-r-lg bg-black/50 text-white',
};

export const Label = ({
  text,
  iconName,
  appearance,
  isDarkTheme = false,
  className = '',
  testId = '',
}: Props) => {
  return (
    <div data-test={testId} className={c({ dark: isDarkTheme })}>
      <div
        className={c(styleClasses.base, styleClasses[appearance], className)}
      >
        {iconName && (
          <DynamicIcon
            aria-hidden="true"
            iconName={iconName}
            className={c('h-[16px] w-[16px] lg:h-[20px] lg:w-[20px]', {
              'mr-1': appearance === 'editorial',
              'mr-2': appearance !== 'editorial',
            })}
          />
        )}
        <span>{text}</span>
      </div>
    </div>
  );
};
